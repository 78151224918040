<template>
  <DefaultPageMenu
      name="settings"
      :routes="Array('languages')"
  />
</template>

<script>
import DefaultPageMenu from "@/components/default/page/DefaultPageMenu";
export default {
  name: "settings",
  components: {DefaultPageMenu}
}
</script>

<style scoped>

</style>
