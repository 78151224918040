<template>
  <v-container class="rel">
    <v-card class="shadow pa-5">
      <v-form v-if="interfaceData.data" lazy-validation ref="form" :key="'text' + key">
        <DevInput
          v-model="interfaceData.data[1].value"
          :rules="[v => !!v || t['required_field']]"
          text-area
          :text="t.title"
        />
        <v-card v-if="pageId.data" class="border pa-5 mb-5" :key="'image' + key">
          <DevImageBlock
            v-if="typeof pageId.data.additional_images === 'object'"
            :text="t.images"
            :images="pageId.data.additional_images"
            :on-delete="deleteItem"
          />
          <v-input v-if="pageId.data.additional_images.length < 1" class="error--text font-weight-bold">
            {{ t['required_field'] }}
          </v-input>
          <div class="text-center">
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="loadImage"
                  color="primary"
                  fab
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>{{ t.add }}</span>
            </v-tooltip>
          </div>
        </v-card>
        <DevInput
          v-model="interfaceData.data[0].value"
          :rules="[v => !!v || t['required_field']]"
          text-area
          :text="t['services']"
        />
      </v-form>
    </v-card>
    <input
      type="file"
      id="file"
      ref="file"
      :accept="'.JPG, .PNG, .SVG, .GIF, .WebP'"
      style="display: none"
      v-on:change="handleFileUpload()"
    />
    <div class="fixed_btn">
      <v-btn color="primary" fab @click="saveInterface">
        <v-icon class="min">mdi-upload</v-icon>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DevInput from "components/dev/DevInput";
import DevImageBlock from "components/dev/DevImageBlock";

export default {
  name: "construction_main",
  components: {DevImageBlock, DevInput},
  data: () => ({
    load_file: '',
    key: 0,
    id: 0,
    modal: false,
  }),
  computed: {
    t() {
      return Object.assign(
        this.$store.state.t[this.$route.params.language]['page_toolbar'],
        this.$store.state.t[this.$route.params.language]['fields'],
        this.$store.state.t[this.$route.params.language]['buttons'],
        this.$store.state.t[this.$route.params.language]['form'],
        this.$store.state.t[this.$route.params.language]['menu'],
      )
    },
    ...mapGetters('interfaceData', ['interfaceData']),
    ...mapGetters('pages', ['pageId']),
    ...mapGetters(["file", "apiDomain", "language"]),
  },
  watch: {
    load_file() {
      this.submitFile();
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    ...mapActions('interfaceData', ['onInterfaceData', 'onInterfaceDataSave']),
    ...mapActions('pages', ['onPage', 'onPageSave']),
    ...mapActions(['uploadFile', 'destroyFile']),
    async loadData() {
      await this.onInterfaceData({id: 46}).then(() => {
        this.key++
      })
      await this.onPage(16).then(() => {
        if (this.pageId.data )
          this.pageId.data.additional_images = JSON.parse(this.pageId.data.additional_images)
        this.key++
      })
    },
    saveInterface() {
      if (this.$refs.form.validate()) {
        this.onInterfaceDataSave({
          language_id: 1,
          translations: [
            {
              interface_entity_id: 473,
              value: this.interfaceData.data[0].value
            },
            {
              interface_entity_id: 456,
              value: this.interfaceData.data[1].value
            }
          ]
        })
      }
    },
    loadImage() {
      this.$refs.file.click();
    },
    handleFileUpload() {
      this.load_file = this.$refs.file.files[0];
    },
    async submitFile() {
      let formData = new FormData();
      formData.append("file", this.load_file);
      await this.uploadFile({
        data: formData,
        use_case: "cover",
      }).then(() => {
        this.pageId.data.additional_images.push(this.file.url)
        this.pageId.data.additional_images = JSON.stringify(this.pageId.data.additional_images)
        this.pageId.data.language_id = 1
        this.onPageSave({id: 16, data: this.pageId.data})
        this.loadData()
      });
    },
    closeModals() {
      this.modalAskDelete = false
    },
    async deleteItem(id) {
      await this.destroyFile(this.pageId.data.additional_images[id])
      this.pageId.data.additional_images.splice(id, 1)
      this.pageId.data.additional_images = JSON.stringify(this.pageId.data.additional_images)
      this.pageId.data.language_id = 1
      await this.onPageSave({id: 16, data: this.pageId.data}).then(() => {
        this.loadData()
      })
    }
  }
}
</script>
