var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.text)?_c('v-input',{staticClass:"font12",attrs:{"hide-details":""}},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e(),(_vm.select)?_c('v-select',{attrs:{"items":_vm.items,"rules":_vm.rules,"item-text":_vm.itemText,"item-value":_vm.itemValue,"menu-props":_vm.menu_props,"hide-details":_vm.hideDetails,"return-object":_vm.returnObject,"disabled":_vm.disabled,"append-icon":"mdi-chevron-down","outlined":"","dense":"","multiple":_vm.multiple,"chips":_vm.chips,"clearable":_vm.clearable,"hint":_vm.hint,"persistent-hint":!!_vm.hint,"label":_vm.label},on:{"input":_vm.inputSelected,"change":_vm.changeSelected,"blur":_vm.blurSelect},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):(_vm.autocomplete)?_c('v-autocomplete',{attrs:{"items":_vm.items,"rules":_vm.rules,"item-text":_vm.itemText,"item-value":_vm.itemValue,"menu-props":_vm.menu_props,"hide-details":_vm.hideDetails,"return-object":_vm.returnObject,"disabled":_vm.disabled,"append-icon":"mdi-chevron-down","multiple":_vm.multiple,"chips":_vm.chips,"outlined":"","dense":"","clearable":_vm.clearable,"hint":_vm.hint,"persistent-hint":!!_vm.hint,"label":_vm.label},on:{"input":_vm.inputSelected,"change":_vm.changeSelected,"blur":_vm.blurSelect},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):(_vm.textArea)?_c('v-textarea',{attrs:{"disabled":_vm.disabled,"hide-details":_vm.hideDetails,"rules":_vm.rules,"outlined":"","auto-grow":"","hint":_vm.hint,"persistent-hint":!!_vm.hint,"label":_vm.label},on:{"input":_vm.inputSelected,"change":_vm.changeSelected,"blur":_vm.blurSelect},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):(_vm.dateTime)?_c('v-datetime-picker',{attrs:{"dateFormat":"dd.MM.yyyy","hint":_vm.hint,"persistent-hint":!!_vm.hint,"textFieldProps":{
      outlined: true,
      dense: true,
      class: 'mb-n4',
      rules: _vm.rules,
      'validate-on-blur': true,
    },"datePickerProps":{
      'no-title': true,
      scrollable: true,
    },"timePickerProps":{
      'no-title': true,
      scrollable: true,
      'ampm-in-title': true,
    },"okText":_vm.t['save'],"clearText":_vm.t['clear']},on:{"input":_vm.inputSelected,"change":_vm.changeSelected},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('template',{slot:"dateIcon"},[_c('v-icon',[_vm._v("mdi-calendar-outline")])],1),_c('template',{slot:"timeIcon"},[_c('v-icon',[_vm._v("mdi-clock-outline")])],1)],2):(_vm.phone)?_c('v-text-field-simplemask',{ref:"phone",attrs:{"properties":{
                prefix: '+358',
                suffix: '',
                color: 'primary',
                outlined: true,
                dense: true,
                placeholder: '',
                required: true,
                'validate-on-blur': true,
                'background-color' : 'white',
                disabled: _vm.disabled,
                rules: [function (v) { return !!v || _vm.t['required_field']; }, function (v) { return (v && v.length >= 12) || _vm.t['bad_phone']; }]
              },"options":{
                inputMask: '## ### ## ##',
                outputMask: '#########',
                empty: null,
                applyAfter: false,
                alphanumeric: false,
                lowerCase: false,
              },"focus":_vm.focus,"validate-on-blur":""},on:{"focus":function($event){_vm.focus = false}},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_c('v-text-field',{attrs:{"hide-details":_vm.hideDetails,"disabled":_vm.disabled,"rules":_vm.rules,"outlined":"","dense":"","type":_vm.type,"hint":_vm.hint,"persistent-hint":!!_vm.hint,"label":_vm.label,"prefix":_vm.prefix},on:{"input":_vm.inputSelected,"change":_vm.changeSelected,"blur":_vm.blurSelect},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('template',{slot:"append-outer"},[_vm._t("append-outer")],2),_c('template',{slot:"append"},[_vm._t("append")],2)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }