<template>
  <div>
    <v-form ref="form" lazy-validation class="grid" :key="key">
      <v-sheet>
        <DevInput
            :text="t['title']"
            v-model="data.title"
            :rules="[v => !!v || t['required_field']]"
        />
        <DevInput
            :text="t['url']"
            v-model="data.url"
            :rules="[v => !!v || t['required_field']]"
        />
        <div class="d-flex align-center">
          <DevImageBlock :images="Array(data.image)" :key="key1" :on-update="loadImage" contain/>
        </div>
        <DevInput
            v-if="articleCategoryList.data"
            :text="t['category']"
            autocomplete
            item-text="title"
            v-model="data.category"
            :items="articleCategoryList.data"
            :rules="[v => !!v || t['required_field']]"
        />
        <DevInput
            v-if="authorList.data"
            :text="t['author']"
            autocomplete
            v-model="data.author"
            :items="authorList.data"
            :rules="[v => !!v || t['required_field']]"
        />
        <DevInput
            text-area
            :text="t['subtext']"
            v-model="data.subtext"
            :rules="[v => !!v || t['required_field']]"
        />
        <v-card class="pa-4 border">
          <v-input hide-details>
            SEO
          </v-input>
          <div class="font12 mb-1 red--text text-start">
            {{ error && !data.seo_image ? t['required_field'] : '' }}
          </div>
          <DevInput
              :text="t['title']"
              v-model="data.seo_title"
              :rules="[v => !!v || t['required_field']]"
          />
          <DevInput
              :text="t['alt']"
              v-model="data.alt"
              :rules="[v => !!v || t['required_field']]"
          />
          <DevInput
              :text="t['keywords']"
              v-model="data.keywords"
              :rules="[v => !!v || t['required_field']]"
          />
          <DevInput
              text-area
              :text="t['description']"
              v-model="data.description"
              :rules="[v => !!v || t['required_field']]"
              class="mb-n4"
          />
        </v-card>
      </v-sheet>
      <v-sheet>
        <v-input hide-details class="font12">
          {{ t['article_text'] }}
        </v-input>
        <VueEditor
          v-model="data.text"
          id="editor"
          :use-custom-image-handler="true"
          @image-added="handleImageAdded"
          @input="checkLink"
        />
      </v-sheet>
    </v-form>
    <v-btn @click="saveData" class="primary mt-5 mb-10">
      {{ data.id ? t['save'] : t['create'] }}
    </v-btn>
    <input
        type="file"
        id="file"
        ref="file"
        accept=".JPG, .PNG, .SVG, .GIF, .WebP"
        style="display: none"
        v-on:change="handleFileUpload()"
    />
  </div>
</template>

<script>
import {VueEditor} from "vue2-editor";
//import { ImageDrop } from "quill-image-drop-module";
//import ImageResize from "quill-image-resize-module";
import {mapActions, mapGetters} from "vuex";
import DevInput from "components/dev/DevInput";
import DevImageBlock from "components/dev/DevImageBlock";
import DefaultPageLanguage from "components/default/page/DefaultPageLanguage";

export default {
  name: "PagesBlogArticleId",
  components: {VueEditor, DefaultPageLanguage, DevImageBlock, DevInput},
  data: () => ({
    data: {},
    key: 0,
    key1: 10000,
    error: false,
    load_file: '',
    deleted_files: [],
    new_files: [],
    click_save: false,
    customModulesForEditor: [
      //{ alias: "imageDrop", module: ImageDrop },
      //{ alias: "imageResize", module: ImageResize }
    ],
    editorSettings: {
      modules: {
        imageDrop: true,
        imageResize: {}
      }
    },
  }),
  computed: {
    t() {
      return Object.assign(
          this.$store.state.t[this.$route.params.language]['page_toolbar'],
          this.$store.state.t[this.$route.params.language]['fields'],
          this.$store.state.t[this.$route.params.language]['buttons'],
          this.$store.state.t[this.$route.params.language]['form']
      )
    },
    ...mapGetters(["language", "file", 'apiDomain']),
    ...mapGetters("article", ["article"]),
    ...mapGetters("author", ["authorList"]),
    ...mapGetters("articleCategory", ["articleCategoryList"])

  },
  watch: {
    article() {
      this.data = Object.assign(this.article.data)
      this.data.category = this.article.article_category.id
      this.data.author = this.article.article_author.id
      if (this.data.image === 'uploads/jAg2Sgh2JrzxdEAF0Ean4PxlIyk95xncmSgndmM8.jpg') this.data.image = null
      this.key++
    },
    language() {
      this.getItem()
    },
    load_file() {
      this.submitFile()
    },
  },
  mounted() {
    this.getItem()
    this.onAuthorList()
    this.onArticleCategoryList()
  },
  beforeDestroy() {
    this.closeItem()
  },
  methods: {
    ...mapActions(["uploadFile", "destroyFile"]),
    ...mapActions("article", ["onArticle", "onArticleSave", "onArticleCreate"]),
    ...mapActions("articleCategory", ["onArticleCategoryList"]),
    ...mapActions("author", ["onAuthorList"]),
    checkLink() {
      let blank = this.data.text.indexOf('<a href="/')
      if (blank > 0) {
        let temp = this.data.text.split('<a href="/')
        this.data.text = temp[0] + '<a href="/' + temp[1].replace('target="_blank"', '')
      }
    },
    closeItem() {
      console.log('закрываем окно')
      if (this.click_save) {
        console.log('нажата кнопка сохранения')
        for (let del in this.deleted_files) {
          this.destroyFile(this.deleted_files[del])
        }
      } else {
        console.log('нажата кнопка выхода')
        for (let del in this.new_files) {
          this.destroyFile(this.new_files[del])
        }
      }
      this.deleted_files = []
      this.new_files = []
      this.click_save = false
    },

    async getItem() {
      if (this.$route.params.id != 0) {
        await this.onArticle(this.$route.params.id).then(res => {
          this.item = Object.assign(res.data)
        })
      }
    },
    async saveData() {
      if (this.$refs.form.validate()) {
        this.data.language_id = this.language
        this.data.article_author_id = this.data.author
        this.data.article_category_id = this.data.category
        if (!this.data.image) this.data.image = 'uploads/jAg2Sgh2JrzxdEAF0Ean4PxlIyk95xncmSgndmM8.jpg'
        this.data.seo_image = 'uploads/jAg2Sgh2JrzxdEAF0Ean4PxlIyk95xncmSgndmM8.jpg'
        this.click_save = true
        this.closeItem()
        if (this.data.id) {
          await this.onArticleSave({id: this.data.id, data: this.data})
        } else {
          await this.onArticleCreate(this.data).then(() => {
            this.$router.push({name: 'articles'})
          })
        }

        //this.onSave(this.data)
      }
    },

    loadImage() {
      this.$refs.file.click()
    },

    handleFileUpload() {
      this.load_file = this.$refs.file.files[0]
    },

    async submitFile() {
      let formData = new FormData()
      formData.append('file', this.load_file)

      await this.uploadFile({
        data: formData,
        use_case: 'cover',
      }).then(() => {
        this.data.image = this.file.url
        this.new_files.push(this.file.url)
        this.key++
      })
    },

    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      let formData = new FormData()
      formData.append('file', file)
      this.uploadFile({
        data: formData,
        use_case: 'cover',
      })
      .then(() => {
        const url = this.apiDomain + '/' + this.file.url
        console.log(url)
        Editor.insertEmbed(cursorLocation, "image", url)
        resetUploader();
      })
    }
  }
}
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  grid-template-rows: auto;
  grid-gap: 20px;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    grid-template-columns: 1fr
  }
}
</style>
