import axios from "axios";

const state = {
  interfaceData: [],
  interfaceGroupList: [],
  textData: [],
};

const getters = {
  interfaceData(state) {
    return state.interfaceData;
  },
  interfaceGroupList(state) {
    return state.interfaceGroupList;
  },
  textData(state) {
    return state.textData;
  },
};

const mutations = {
  setInterfaceData: (state, item) => {
    state.interfaceData = item;
  },
  setInterfaceGroupList: (state, item) => {
    state.interfaceGroupList = item;
  },
  setTextData: (state, item) => {
    state.textData = item;
  },
};

const actions = {
  onInterfaceData({commit, dispatch}, {id}) {
    commit("setInterfaceData", {});
    return new Promise((resolve, reject) => {
      let url = this.state.apiDomain + "/api/interface?language_id=1&interface_group_id=" + id;
      axios
        .get(url)
        .then(response => {
          commit("setInterfaceData", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          dispatch("notification/onNotification", {IsError: true}, {root: true});
        });
    });
  },
  onInterfaceDataDelete({commit, dispatch}, id) {
    return new Promise((resolve, reject) => {
      let url = this.state.apiDomain + "/api/interface/" + id;
      axios
        .delete(url)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          dispatch("notification/onNotification", {IsError: true}, {root: true});
        });
    });
  },
  onTextDataDelete({commit, dispatch}, id) {
    return new Promise((resolve, reject) => {
      let url = this.state.apiDomain + "/api/text/" + id;
      axios
        .delete(url)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          dispatch("notification/onNotification", {IsError: true}, {root: true});
        });
    });
  },
  onTextData({commit, dispatch}, {id}) {
    commit("setTextData", {});
    return new Promise((resolve, reject) => {
      let url = this.state.apiDomain + "/api/text?language_id=1&interface_group_id=" + id;
      axios
        .get(url)
        .then(response => {
          commit("setTextData", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          dispatch("notification/onNotification", {IsError: true}, {root: true});
        });
    });
  },
  onInterfaceDataSave({commit, dispatch}, data) {
    commit("SetLoadingAnimation", true, {root: true});
    return new Promise((resolve, reject) => {
      let url = this.state.apiDomain + "/api/interface/update";
      axios
        .put(url, data)
        .then(response => {
          commit("SetLoadingAnimation", false, {root: true});
          resolve(response);
          dispatch("notification/onNotification", {IsError: false}, {root: true});
        })
        .catch(error => {
          commit("SetLoadingAnimation", false, {root: true});
          reject(error);
          dispatch("notification/onNotification", {IsError: true}, {root: true});
        });
    });
  },
  onTextDataSave({commit, dispatch}, data) {
    commit("SetLoadingAnimation", true, {root: true});
    return new Promise((resolve, reject) => {
      let url = this.state.apiDomain + "/api/text/update";
      axios
        .put(url, data)
        .then(response => {
          commit("SetLoadingAnimation", false, {root: true});
          resolve(response);
          dispatch("notification/onNotification", {IsError: false}, {root: true});
        })
        .catch(error => {
          commit("SetLoadingAnimation", false, {root: true});
          reject(error);
          dispatch("notification/onNotification", {IsError: true}, {root: true});
        });
    });
  },
  onInterfaceDataSavePage({commit, dispatch}, {id, data}) {
    commit("SetLoadingAnimation", true, {root: true});
    return new Promise((resolve, reject) => {
      let url = this.state.apiDomain + "/api/interface/" + id;
      axios
        .put(url, data)
        .then(response => {
          commit("SetLoadingAnimation", false, {root: true});
          resolve(response);
          dispatch("notification/onNotification", {IsError: false}, {root: true});
        })
        .catch(error => {
          commit("SetLoadingAnimation", false, {root: true});
          reject(error);
          dispatch("notification/onNotification", {IsError: true}, {root: true});
        });
    });
  },
  onInterfaceDataCreate({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      let url = this.state.apiDomain +"/api/interface";
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
          dispatch("notification/onNotification", {IsError: false}, {root: true});
        })
        .catch(error => {
          reject(error);
          dispatch("notification/onNotification", {IsError: true}, {root: true});
        });
    });
  },
  onTextDataCreate({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      let url = this.state.apiDomain +"/api/text";
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
          dispatch("notification/onNotification", {IsError: false}, {root: true});
        })
        .catch(error => {
          reject(error);
          dispatch("notification/onNotification", {IsError: true}, {root: true});
        });
    });
  },
  onInterfaceGroupList({ commit, dispatch }, id) {
    commit("SetLoadingAnimation", true, {root: true});
    return new Promise((resolve, reject) => {
      let url = this.state.apiDomain +"/api/interfaceGroup?language_id=1&page_id=" + id;
      axios
        .get(url)
        .then(response => {
          commit("SetLoadingAnimation", false, {root: true});
          commit("setInterfaceGroupList", response.data);
          resolve(response);
          dispatch("notification/onNotification", {IsError: false}, {root: true});
        })
        .catch(error => {
          commit("SetLoadingAnimation", false, {root: true});
          reject(error);
          dispatch("notification/onNotification", {IsError: true}, {root: true});
        });
    });
  },
  onInterfaceGroupCreate({ commit, dispatch }, data) {
    commit("SetLoadingAnimation", true, {root: true});
    return new Promise((resolve, reject) => {
      let url = this.state.apiDomain +"/api/interfaceGroup";
      axios
        .post(url, data)
        .then(response => {
          commit("SetLoadingAnimation", false, {root: true});
          resolve(response);
          dispatch("notification/onNotification", {IsError: false}, {root: true});
        })
        .catch(error => {
          commit("SetLoadingAnimation", false, {root: true});
          reject(error);
          dispatch("notification/onNotification", {IsError: true}, {root: true});
        });
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
