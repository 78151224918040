<template>
  <v-card class="d-flex flex-wrap flex-md-nowrap gap-4 border px-4 pt-4" width="100%">
    <v-icon v-if="item['name']" color="primary">mdi-{{item['name']}}</v-icon>
    <DevImageBlock
      v-else
      :text="t['image']"
      :images="Array(item['image'])"
      contain
      :on-update="loadImage"
    />
    <div class="flex-grow-1">
      <DevInput v-model="item['link']" :text="t['url']" />
    </div>
    <input
      type="file"
      id="file"
      ref="file"
      :accept="'.JPG, .PNG, .SVG, .GIF, .WebP'"
      style="display: none"
      v-on:change="handleFileUpload()"
    />
  </v-card>
</template>

<script>
import DevImageBlock from "components/dev/DevImageBlock";
import {mapActions, mapGetters} from "vuex";
import DevInput from "components/dev/DevInput";

export default {
  name: "PagesCompanyContactsItem",
  components: {DevInput, DevImageBlock},
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          image: null,
          link: null
        }
      }
    }
  },
  data: () => ({
    deleted_files: [],
    new_files: [],
    load_file: '',
    click_save: false,
  }),
  computed: {
    ...mapGetters(["file"]),
    t() {
      return Object.assign(
        this.$store.state.t[this.$route.params.language]['fields'],
        this.$store.state.t[this.$route.params.language]['form'],
        this.$store.state.t[this.$route.params.language]['buttons']
      )
    },
  },
  watch: {
    load_file() {
      this.submitFile();
    },
  },
  methods: {
    ...mapActions(['uploadFile', 'destroyFile']),
    loadImage() {
      this.$refs.file.click()
    },
    handleFileUpload() {
      this.click_save = false
      this.load_file = this.$refs.file.files[0];
    },
    async submitFile() {
      let formData = new FormData();
      formData.append("file", this.load_file);
      await this.uploadFile({
        data: formData,
        use_case: "icon",
      }).then(() => {
        this.item.image = this.file.url
      });
    },
  }
}
</script>