<template>
  <v-app>
    <div v-if="$route.params.language && ($route.params.language === 'ru' || $route.params.language === 'fi' || $route.params.language === 'en') && $route.name">
      <router-view v-if="!authLoggedIn"/>
      <div v-else-if="authUser">
        <div v-if="authUser['first_name'] && authUser['last_name'] && authUser['phone']">
          <DefaultHeader/>
          <v-main>
            <div class="pa-1 px-lg-6 py-lg-4">
              <DefaultBreadcrumbs/>
              <router-view/>
            </div>
            <DefaultFooter/>
          </v-main>
        </div>
        <router-view v-else/>
      </div>
      <v-overlay :value="loadingAnimation" :z-index="300">
        <v-progress-circular
            indeterminate
            color="secondary"
            size="64"
        ></v-progress-circular>
      </v-overlay>
      <DefaultNotification/>
    </div>
    <router-view v-else/>
  </v-app>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import DefaultHeader from "@/components/default/DefaultHeader";
import DefaultFooter from "@/components/default/DefaultFooter";
import DefaultBreadcrumbs from "@/components/default/DefaultBreadcrumbs";
import DefaultNotification from "@/components/default/DefaultNotification";

export default {
  name: 'App',
  components: {DefaultNotification, DefaultBreadcrumbs, DefaultFooter, DefaultHeader},
  data: () => ({
    language: 'ru',
  }),
  computed: {
    ...mapGetters(["loadingAnimation", "application_language"]),
    ...mapGetters("auth", ["authLoggedIn"]),
    ...mapGetters("authUser", ["authUser"])
  },
  created() {
    document.title = 'Admin - ProApp -toiminnanohjaus urakointiin'
    this.onAuthIsLoggedIn();
    this.checkLanguageApplication()
    this.checkLanguage();
  },
  mounted() {
    this.checkLanguage();
  },
  methods: {
    ...mapActions(["onLanguage", "checkLanguageApplication"]),
    ...mapActions("auth", ["onAuthIsLoggedIn"]),
    ...mapActions("authUser", ["onAuthUser"]),
    async checkLanguage() {
      let temp = this.$route.fullPath.split('/')[1]
      if(temp !== 'ru' && temp !== 'en' && temp !== 'fi') {
          if (this.application_language) {
            this.onLanguage(this.application_language.name)
            await this.checkLogin(this.application_language.name)
          }
          else {
            if (this.$route.fullPath !== '/select_language') {
              this.$store.commit('SetTempRoute', this.$route.fullPath)
            }
            this.$router.push({name: 'select_language'}).catch(() => {});
          }
      } else {
        this.onLanguage(temp)
        await this.checkLogin(temp)
      }
    },
    async checkLogin(lang) {
      if (this.authLoggedIn) {
        await this.getUser(lang);
        if (this.$route.name === "login" || this.$route.fullPath === '/') {
          await this.$router.push({name: 'home', params: {language: lang}}).catch(() => {
          });
        }
      } else if (this.$route.name !== "confirmEmail" && this.$route.name !== "confirmRestore") {
        this.$router.push({name: "login", params: {language: lang}}).catch(() => {
        });
      }
    },
    async getUser(lang) {
      await this.onAuthUser().then(() => {
        if (!this.authUser['first_name'] || !this.authUser['last_name'] || !this.authUser['phone']) {
          this.$router.push({name: "userData", params: {language: lang}}).catch(() => {
          });
        }
      })
    }
  }
};
</script>
