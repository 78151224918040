<template>
  <v-container>
    <v-form lazy-validation ref="form" v-if="pageId.data && interfaceData.data && textData.data">
      <DevInput
        :text="t['title']"
        v-if="interfaceData.data[0]"
        v-model="interfaceData.data[0].value"
        :rules="[v => !!v || t['required_field']]"
      />
      <v-input hide-details class="text--darken-1 font12">
        {{ t['article_text'] }}
      </v-input>
      <VueEditor
        v-if="textData.data[4]"
        v-model="textData.data[4].value"
        @input="checkLink(4)"
      />
      <div class="gr py-4">
        <div>
          <div v-for="i in [3, 2, 1]" :key="i" class="d-flex align-center pb-3">
            <div class="flex-grow-1">
              <VueEditor
                v-if="textData.data[i]"
                v-model="textData.data[i].value"
                @input="checkLink(i)"
              />
            </div>
            <v-btn @click="clearData(i)" icon color="red" :disabled="!textData.data[i].value">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="d-flex align-center">
          <div class="flex-grow-1">
            <VueEditor
              v-if="textData.data[0]"
              v-model="textData.data[0].value"
              @input="checkLink(0)"
            />
          </div>
          <v-btn @click="clearData(0)" icon color="red" :disabled="!textData.data[0].value">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
      <v-card class="my-5 pa-4 border text-start">
        SEO
        <div class="grid_seo">
          <div>
            <DevInput
              v-model="pageId.data['seo_title']"
              :text="t['title']"
              :rules="[v => !!v || t['required_field']]"
            />
            <DevInput
              v-model="pageId.data['keywords']"
              :text="t['keywords']"
              :rules="[v => !!v || t['required_field']]"
            />
          </div>
          <div>
            <div>
              <v-input hide-details class="font12 text-center">
                {{ t['image'] }}
              </v-input>
              <DevImageBlock :images="[pageId.data.image]" contain :on-update="loadImage"/>
            </div>
          </div>
          <DevInput
            v-model="pageId.data['description']"
            :text="t['description']"
            text-area
            :rules="[v => !!v || t['required_field']]"
            class="mb-n4"
          />
        </div>
      </v-card>
    </v-form>
    <input
      type="file"
      id="file"
      ref="file"
      :accept="'.JPG, .PNG, .SVG, .GIF, .WebP'"
      style="display: none"
      v-on:change="handleFileUpload()"
    />
    <div class="fixed_btn">
      <v-btn @click="saveData" color="primary" fab>
        <v-icon class="min">mdi-upload</v-icon>
      </v-btn>
    </div>
    <v-dialog v-model="modalAskDelete" width="600">
      <DefaultPageAskDelete
        text="text"
        :on-close="closeModals"
        :on-delete="deleteItem"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {VueEditor} from "vue2-editor";
import DevInput from "components/dev/DevInput";
import DevImageBlock from "components/dev/DevImageBlock";
import DefaultPageAskDelete from "components/default/page/DefaultPageAskDelete";

export default {
  name: "agree",
  components: {DefaultPageAskDelete, DevImageBlock, DevInput, VueEditor},
  computed: {
    t() {
      return Object.assign(
        this.$store.state.t[this.$route.params.language]['fields'],
        this.$store.state.t[this.$route.params.language]['form'],
        this.$store.state.t[this.$route.params.language]['buttons']
      )
    },
    ...mapGetters(["file"]),
    ...mapGetters('pages', ['pageList', 'pageId']),
    ...mapGetters('interfaceData', ['interfaceGroupList', 'interfaceData', 'textData'])
  },
  data: () => ({
    idText: -1,
    modalAskDelete: false,
    deleted_files: [],
    new_files: [],
    click_save: false,
    load_file: '',
    toolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ["bold", "italic"],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" }
      ],
      ["blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ color: ['#277faf', '#212121'] }],
      ["link"],
      ["clean"]
    ],
  }),
  watch: {
    load_file() {
      this.submitFile();
    },
  },
  created() {
    this.onPage(30)
    this.onInterfaceData({id: 54})
    this.onTextData({id: 54})
  },
  beforeDestroy() {
    this.onClose()
  },
  methods: {
    ...mapActions(['uploadFile', 'destroyFile']),
    ...mapActions('pages', ['onPageList', 'onPage', 'onPageSave', 'onPageCreate']),
    ...mapActions('interfaceData', ['onInterfaceGroupCreate', 'onInterfaceGroupList', 'onInterfaceData', 'onTextData', 'onTextDataSave', 'onInterfaceDataDelete', 'onInterfaceDataCreate', 'onInterfaceDataSave', 'onInterfaceDataSavePage']),
    clearData(id) {
      this.idText = id
      this.modalAskDelete = true
    },
    closeModals() {
      this.modalAskDelete = false
    },
    deleteItem() {
      this.textData.data[this.idText].value = null
      this.closeModals()
    },
    checkLink(id) {
      let blank = this.textData.data[id].value.indexOf('<a href="/')
      if (blank > 0) {
        let temp = this.textData.data[id].value.split('<a href="/')
        this.textData.data[id].value = temp[0] + '<a href="/' + temp[1].replace('target="_blank"', '')
      }
    },
    onClose() {
      if (this.click_save) {
        for (let del in this.deleted_files) {
          this.destroyFile(this.deleted_files[del])
        }
      } else {
        for (let del in this.new_files) {
          this.destroyFile(this.new_files[del])
        }
      }
      this.deleted_files = []
      this.new_files = []
      this.click_save = false
    },
    loadImage() {
      this.$refs.file.click();
    },
    handleFileUpload() {
      this.click_save = false
      this.load_file = this.$refs.file.files[0];
    },
    async submitFile() {
      let formData = new FormData();
      formData.append("file", this.load_file);
      await this.uploadFile({
        data: formData,
        use_case: "icon",
      }).then(() => {
        if (this.pageId.data.image && this.pageId.data.image.length > 1) {
          this.deleted_files.push(this.pageId.data.image)
        }
        this.pageId.data.image = this.file.url
        this.new_files.push(this.file.url)
      });
    },
    saveData() {
      if (this.$refs.form.validate()) {
        this.click_save = true
        let result = []
        for (let i in this.interfaceData.data) {
          result.push({
            interface_entity_id: this.interfaceData.data[i].id,
            value: this.interfaceData.data[i].value
          })
        }
        let result1 = []
        for (let i in this.textData.data) {
          result1.push({
            text_entity_id: this.textData.data[i].id,
            value: this.textData.data[i].value
          })
        }
        this.onInterfaceDataSave({
          language_id: 1,
          translations: result
        })
        this.onTextDataSave({
          language_id: 1,
          translations: result1
        })
        this.pageId.data.language_id = 1
        this.onPageSave({id: 30, data: this.pageId.data})
        this.onClose()
      }
    },
  }
}
</script>

<style scoped lang="scss">
.gr {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
  
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    grid-template-columns: 1fr;
  }
}
</style>
