<template>
  <DefaultPageMenu
    name="cleaning"
    :routes="Array('cleaning_main', 'cleaning_services', 'cleaning_seo')"
  />
</template>

<script>
import DefaultPageMenu from "components/default/page/DefaultPageMenu";
export default {
  name: "cleaning",
  components: {DefaultPageMenu}
}
</script>

<style scoped>

</style>
