<template>
  <v-container>
    <v-form lazy-validation ref="form" v-if="pageId.data">
      <DevInput
        v-if="interfaceData.data && interfaceData.data[2]"
        :text="t['title']"
        v-model="interfaceData.data[2].value"
      />
      <v-input hide-details class="text--darken-1 font12">
        {{ t['article_text'] }}
      </v-input>
      <VueEditor
        v-model="article.value"
        @input="checkLink"
      />
      <div
        v-for="(c, cnt) in contacts" :key="cnt + '_' + key"
        class="d-flex flex-column flex-md-row justify-end mt-5"
      >
        <PagesCompanyContactsItem :item="c"/>
        <div class="d-flex flex-row flex-md-column order-first order-md-2">
          <v-btn icon color="primary" @click="changePosition('up', cnt)" :disabled="cnt === 0">
            <v-icon>mdi-arrow-up-thick</v-icon>
          </v-btn>
          <v-btn icon color="primary" @click="changePosition('down', cnt)" :disabled="contacts.length === cnt + 1">
            <v-icon>mdi-arrow-down-thick</v-icon>
          </v-btn>
          <v-btn icon color="red" @click="id = cnt, modalAskDelete = true" :disabled="!!c.name">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="flex-grow-1 text-center mt-7">
        <v-btn :key="article.length" @click="addData" color="primary" fab :disabled="disabledAddButton">
          <v-icon class="min">mdi-plus</v-icon>
        </v-btn>
      </div>
      <v-card class="my-5 pa-4 border text-start">
        SEO
        <div class="grid_seo">
          <div>
            <DevInput
              v-model="pageId.data['seo_title']"
              :text="t['title']"
              :rules="[v => !!v || t['required_field']]"
            />
            <DevInput
              v-model="pageId.data['keywords']"
              :text="t['keywords']"
              :rules="[v => !!v || t['required_field']]"
            />
          </div>
          <div>
            <div>
              <v-input hide-details class="font12 text-center">
                {{ t['image'] }}
              </v-input>
              <DevImageBlock :images="[pageId.data.image]" contain :on-update="loadImage"/>
            </div>
          </div>
          <DevInput
            v-model="pageId.data['description']"
            :text="t['description']"
            text-area
            :rules="[v => !!v || t['required_field']]"
            class="mb-n4"
          />
        </div>
      </v-card>
    </v-form>
    <input
      type="file"
      id="file"
      ref="file"
      :accept="'.JPG, .PNG, .SVG, .GIF, .WebP'"
      style="display: none"
      v-on:change="handleFileUpload()"
    />
    <div class="fixed_btn">
      <v-btn @click="saveData" color="primary" fab>
        <v-icon class="min">mdi-upload</v-icon>
      </v-btn>
    </div>
    <v-dialog v-model="modalAskDelete" width="600">
      <DefaultPageAskDelete
        text="address"
        :on-close="closeModals"
        :on-delete="deleteItem"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {VueEditor} from "vue2-editor";
import DevInput from "components/dev/DevInput";
import DevImageBlock from "components/dev/DevImageBlock";
import PagesCompanyContactsItem from "components/pages/company/PagesCompanyContactsItem";
import DefaultPageAskDelete from "components/default/page/DefaultPageAskDelete";

export default {
  name: "company",
  components: {DefaultPageAskDelete, PagesCompanyContactsItem, DevImageBlock, DevInput, VueEditor},
  computed: {
    ...mapGetters(["file"]),
    ...mapGetters('pages', ['pageList', 'pageId']),
    ...mapGetters('interfaceData', ['interfaceGroupList', 'interfaceData', 'textData']),
    t() {
      return Object.assign(
        this.$store.state.t[this.$route.params.language]['fields'],
        this.$store.state.t[this.$route.params.language]['form'],
        this.$store.state.t[this.$route.params.language]['buttons']
      )
    },
    disabledAddButton() {
      let l = this.contacts ? this.contacts.length : []
      if (l > 0)
        return this.contacts[l - 1].name ? false :
          !this.contacts[l - 1].link.length || !this.contacts[l - 1].image.length
      return false
    },
  },
  data: () => ({
    key: 0,
    modalAskDelete: false,
    id: -1,
    deleted_files: [],
    new_files: [],
    click_save: false,
    load_file: '',
    toolbar: [
      [{header: [false, 1, 2, 3, 4, 5, 6]}],
      ["bold", "italic"],
      [
        {align: ""},
        {align: "center"},
        {align: "right"},
        {align: "justify"}
      ],
      ["blockquote"],
      [{list: "ordered"}, {list: "bullet"}],
      [{indent: "-1"}, {indent: "+1"}],
      [{color: ['#277faf', '#212121']}],
      ["link"],
      ["clean"]
    ],
    article: {},
    contacts: [],
    contactsId: -1
  }),
  watch: {
    load_file() {
      this.submitFile();
    },
  },
  created() {
    this.getData()
  },
  beforeDestroy() {
    this.onClose()
  },
  methods: {
    ...mapActions(['uploadFile', 'destroyFile']),
    ...mapActions('pages', ['onPageList', 'onPage', 'onPageSave', 'onPageCreate']),
    ...mapActions('interfaceData',
      ['onInterfaceGroupCreate', 'onInterfaceGroupList', 'onInterfaceData', 'onTextData', 'onTextDataSave',
        'onInterfaceDataDelete', 'onInterfaceDataCreate', 'onInterfaceDataSave', 'onInterfaceDataSavePage']),

    async getData() {
      await this.onPage(31)
      await this.onInterfaceData({id: 55})
      await this.onTextData({id: 55}).then(() => {
        this.article = this.textData.data.filter(i => i.name.includes('text'))[0]
        let contacts = this.textData.data.filter(i => i.name.includes('contact'))[0]
        this.contactsId = contacts.id
        this.contacts = JSON.parse(contacts.value)
      })
    },
    checkLink() {
      let blank = this.article.value.indexOf('<a href="/')
      if (blank > 0) {
        let temp = this.article.value.split('<a href="/')
        this.article.value = temp[0] + '<a href="/' + temp[1].replace('target="_blank"', '')
      }
    },
    onClose() {
      if (this.click_save) {
        for (let del in this.deleted_files) {
          this.destroyFile(this.deleted_files[del])
        }
      } else {
        for (let del in this.new_files) {
          this.destroyFile(this.new_files[del])
        }
      }
      this.deleted_files = []
      this.new_files = []
      this.click_save = false
    },
    loadImage() {
      this.$refs.file.click();
    },
    handleFileUpload() {
      this.click_save = false
      this.load_file = this.$refs.file.files[0];
    },
    async submitFile() {
      let formData = new FormData();
      formData.append("file", this.load_file);
      await this.uploadFile({
        data: formData,
        use_case: "icon",
      }).then(() => {
        if (this.pageId.data.image && this.pageId.data.image.length > 1) {
          this.deleted_files.push(this.pageId.data.image)
        }
        this.pageId.data.image = this.file.url
        this.new_files.push(this.file.url)
      });
    },
    saveData() {
      if (this.$refs.form.validate()) {
        this.click_save = true
        let result = []
        for (let i in this.interfaceData.data) {
          result.push({
            interface_entity_id: this.interfaceData.data[i].id,
            value: this.interfaceData.data[i].value
          })
        }
        let result1 = [
          {
            text_entity_id: this.article.id,
            value: this.article.value
          },
          {
            text_entity_id: this.contactsId,
            value: JSON.stringify(this.contacts)
          },
        ]
        /*for (let i in this.textData.data) {
         result1.push({
         text_entity_id: this.textData.data[i].id,
         value: this.textData.data[i].value
         })
         }*/
        this.onInterfaceDataSave({
          language_id: 1,
          translations: result
        })
        this.onTextDataSave({
          language_id: 1,
          translations: result1
        })
        this.pageId.data.language_id = 1
        this.onPageSave({id: 31, data: this.pageId.data})
        this.onClose()
      }
    },
    addData() {
      this.contacts.push({
        image: '',
        link: ''
      })
    },
    changePosition(pos, id) {
      let elem = this.contacts.splice(id, 1)
      switch (pos) {
        case 'up':
          this.contacts.splice(id - 1, 0, elem[0])
          break
        case 'down':
          this.contacts.splice(id + 1, 0, elem[0])
          break
        case 'delete':
          this.contacts.splice(id, 1)
          break
      }
      this.key++
    },
    closeModals() {
      this.modalAskDelete = false
    },
    deleteItem() {
      this.changePosition('delete', this.id)
      this.closeModals()
    },
  }
}
</script>

<style scoped>

</style>
