<template>
  <v-sheet width="172" height="208" max-width="50vw" max-height="40vh">
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 86 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          id="paint0_linear_7_16"
          x1="0.100486"
          y1="51.9206"
          x2="67.9685"
          y2="51.9206"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#277FAF" />
          <stop offset="0.3356" stop-color="#2578AD" />
          <stop offset="0.8385" stop-color="#1E63A6" />
          <stop offset="1" stop-color="#1C5BA3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7_16"
          x1="0.100486"
          y1="51.9151"
          x2="50.1563"
          y2="51.9151"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="8.24799e-08" stop-color="#1C5BA3" />
          <stop offset="0.1615" stop-color="#1E63A6" />
          <stop offset="0.6644" stop-color="#2578AD" />
          <stop offset="1" stop-color="#277FAF" />
        </linearGradient>
        <mask id="mask-1" x="0" y="0" width="86" height="104">
          <g transform="matrix(1 0 0 1 43.02 51.99)">
            <path
              id="path-1"
              fill="none"
              stroke="#fff"
              stroke-width="60"
              stroke-dasharray="500 500"
              stroke-linecap="round"
              d="M -42.96693 51.95378 L -42.96693 -5.083889999999997 C -42.96693 -5.083889999999997 -42.899119999999996 -27.921349999999997 -32.487919999999995 -38.10865 C -22.076729999999998 -48.29595 -11.451629999999994 -52.270109999999995 -0.03289999999999793 -51.93427 C 11.385830000000002 -51.59843 28.85977 -45.38529 35.35277 -33.96656 C 41.84577 -22.54783 43.25697 -11.576890000000002 42.92112 -4.524150000000002 C 42.585280000000004 2.5285899999999977 36.24835 21.78371 24.71768 28.61256 C 13.187000000000001 35.44141 5.238670000000003 35.8892 -2.7096599999999995 35.8892 C -10.65799 35.8892 -20.3975 35.8892 -20.3975 35.8892"
            />
          </g>
        </mask>
      </defs>
      <g mask="url(#mask-1)">
        <path
          d="M0.0996552 103.466V44.4154C0.0996552 35.7527 2.29103 27.1914 6.71147 19.7475C12.1988 10.5082 22.0471 1.05817 39.4949 0.211186C74.3883 -1.48278 83.3938 26.7301 84.5183 32.3727C85.6427 38.0153 89.5832 57.1996 75.5128 73.5607C65.2183 85.5318 49.6545 87.6871 42.0393 87.9833C39.5702 88.0788 37.5236 86.0846 37.5236 83.6072V62.9078C37.5236 60.6393 39.247 58.7365 41.5018 58.5477C47.487 58.0486 58.0314 55.3923 57.2222 42.8109C56.0958 25.3205 37.5236 28.7044 33.5851 32.655C33.2559 32.9851 32.9525 33.3052 32.6689 33.6173C30.3407 36.1881 29.0814 39.5542 29.0298 43.0256L28.5539 76.0043C28.5261 77.8812 28.2544 79.7461 27.7249 81.5474C25.5811 88.8382 18.5429 105.915 0.0996552 103.466Z"
          fill="#277FAF"
        />
        <path
          d="M67.9688 7.57158C61.2757 2.8734 52.066 -0.399213 39.4968 0.211172C22.0491 1.05617 12.2008 10.5082 6.71342 19.7475C2.29299 27.1914 0.101616 35.7527 0.101616 44.4153V103.466C18.5449 105.917 25.5831 88.8402 27.7268 81.5474C28.2563 79.7461 28.53 77.8811 28.5558 76.0042L29.0317 43.0256C29.0655 40.6536 29.6782 38.3373 30.7967 36.2835C30.7987 36.2716 30.7987 36.2597 30.8007 36.2477C32.7958 15.2998 67.9668 7.57158 67.9688 7.57158Z"
          fill="url(#paint0_linear_7_16)"
        />
        <path
          d="M50.1562 0.678442C46.9039 0.203257 43.358 0.0243169 39.4948 0.21121C22.0491 1.0562 12.2008 10.5082 6.71342 19.7475C2.29299 27.1914 0.101616 35.7527 0.101616 44.4154V103.466C1.46205 103.647 2.75308 103.71 3.99255 103.688C18.1185 87.6036 9.77738 69.5823 11.3579 44.2205C13.0456 17.137 50.1562 0.678442 50.1562 0.678442Z"
          fill="url(#paint1_linear_7_16)"
        />
      </g>
    </svg>
  </v-sheet>
</template>

<script>
export default {
  name: "PagesMainLogo"
}
</script>

<style scoped lang="scss">
#path-1 {
  //opacity: 0;
  animation-name: anim;
  animation-fill-mode: forwards;
  animation-duration: 1.5s;
  animation-timing-function: ease-in;
}

@keyframes anim {
  from {
    stroke-dashoffset: 500;
  }
  to {
    stroke-dashoffset: 0;
  }
}
</style>
