<template>
  <DefaultPageMenu
    name="main"
    :routes="Array('main_seo', 'features')"
  />
</template>

<script>
import DefaultPageMenu from "components/default/page/DefaultPageMenu";

export default {
  name: "main_page",
  components: {DefaultPageMenu},
}
</script>
