<template>
 <v-container>
  <v-form lazy-validation ref="form" v-if="pageId.data && interfaceData.data && textData.data">
   <DevInput
    :text="t['title']"
    v-if="interfaceData.data[0]"
    v-model="interfaceData.data[0].value"
    :rules="[v => !!v || t['required_field']]"
   />
   <div
    v-for="(c, cnt) in cards" :key="cnt + '_' + key"
    class="d-flex flex-column flex-md-row justify-start mt-5"
   >
    <PagesFaqArticlesItem :item="c"/>
    <div class="d-flex flex-row flex-md-column order-first order-md-2">
     <v-btn icon color="primary" @click="changePosition('up', cnt)" :disabled="cnt === 0">
      <v-icon>mdi-arrow-up-thick</v-icon>
     </v-btn>
     <v-btn icon color="primary" @click="changePosition('down', cnt)" :disabled="cards.length === cnt + 1">
      <v-icon>mdi-arrow-down-thick</v-icon>
     </v-btn>
     <v-btn icon color="red" @click="id = cnt, modalAskDelete = true" :disabled="!!c.name">
      <v-icon>mdi-close</v-icon>
     </v-btn>
    </div>
   </div>
   <div class="flex-grow-1 text-center mt-7">
    <v-btn :key="cards.length" @click="addData" color="primary" fab>
     <v-icon class="min">mdi-plus</v-icon>
    </v-btn>
   </div>
   
   <v-card class="my-5 pa-4 border text-start">
    SEO
    <div class="grid_seo">
     <div>
      <DevInput
       v-model="pageId.data['seo_title']"
       :text="t['title']"
       :rules="[v => !!v || t['required_field']]"
      />
      <DevInput
       v-model="pageId.data['keywords']"
       :text="t['keywords']"
       :rules="[v => !!v || t['required_field']]"
      />
     </div>
     <div>
      <div>
       <v-input hide-details class="font12 text-center">
        {{ t['image'] }}
       </v-input>
       <DevImageBlock :images="[pageId.data.image]" contain :on-update="loadImage"/>
      </div>
     </div>
     <DevInput
      v-model="pageId.data['description']"
      :text="t['description']"
      text-area
      :rules="[v => !!v || t['required_field']]"
      class="mb-n4"
     />
    </div>
   </v-card>
  </v-form>
  <input
   type="file"
   id="file"
   ref="file"
   :accept="'.JPG, .PNG, .SVG, .GIF, .WebP'"
   style="display: none"
   v-on:change="handleFileUpload()"
  />
  <div class="fixed_btn">
   <v-btn @click="saveData" color="primary" fab>
    <v-icon class="min">mdi-upload</v-icon>
   </v-btn>
  </div>
  <v-dialog v-model="modalAskDelete" width="600">
   <DefaultPageAskDelete
    text="article"
    :on-close="closeModals"
    :on-delete="deleteItem"
   />
  </v-dialog>
 </v-container>
</template>

<script>
import DevImageBlock from "components/dev/DevImageBlock.vue";
import DevInput from "components/dev/DevInput.vue";
import {mapActions, mapGetters} from "vuex";
import PagesCompanyContactsItem from "components/pages/company/PagesCompanyContactsItem.vue";
import PagesFaqArticlesItem from "components/pages/faq/PagesFaqArticlesItem.vue";
import DefaultPageAskDelete from "components/default/page/DefaultPageAskDelete.vue";

export default {
 name: "faq",
 components: {DefaultPageAskDelete, PagesFaqArticlesItem, PagesCompanyContactsItem, DevInput, DevImageBlock},
 computed: {
  t() {
   return Object.assign(
    this.$store.state.t[this.$route.params.language]['fields'],
    this.$store.state.t[this.$route.params.language]['form'],
    this.$store.state.t[this.$route.params.language]['buttons']
   )
  },
  ...mapGetters(["file"]),
  ...mapGetters('pages', ['pageList', 'pageId']),
  ...mapGetters('interfaceData', ['interfaceGroupList', 'interfaceData', 'textData'])
 },
 data: () => ({
  key: 0,
  deleted_files: [],
  new_files: [],
  click_save: false,
  load_file: '',
  article: {},
  cards: [],
  cardId: -1,
  modalAskDelete: false,
  id: -1,
 }),
 created() {
  this.getData()

 },
 beforeDestroy() {
  this.onClose()
 },
 watch: {
  load_file() {
   this.submitFile();
  },
 },
 methods: {
  ...mapActions(['uploadFile', 'destroyFile']),
  ...mapActions('pages', ['onPageList', 'onPage', 'onPageSave', 'onPageCreate']),
  ...mapActions('interfaceData', ['onInterfaceGroupCreate', 'onInterfaceGroupList', 'onInterfaceData', 'onTextData', 'onTextDataSave', 'onInterfaceDataDelete', 'onInterfaceDataCreate', 'onInterfaceDataSave', 'onInterfaceDataSavePage']),
  async getData() {
   await this.onPage(37)
   await this.onInterfaceData({id: 61})
   await this.onTextData({id: 61}).then(() => {
    let cards = this.textData.data.filter(i => i.name.includes('card'))[0]
    this.cardId = cards.id
    this.cards = JSON.parse(cards.value)
   })
  },
  clearData(id) {
   this.idText = id
   this.modalAskDelete = true
  },
  closeModals() {
   this.modalAskDelete = false
  },
  deleteItem() {
   this.cards.splice(this.id, 1)
   this.key++
   this.closeModals()
  },
  onClose() {
   if (this.click_save) {
    for (let del in this.deleted_files) {
     this.destroyFile(this.deleted_files[del])
    }
   } else {
    for (let del in this.new_files) {
     this.destroyFile(this.new_files[del])
    }
   }
   this.deleted_files = []
   this.new_files = []
   this.click_save = false
  },
  loadImage() {
   this.$refs.file.click();
  },
  handleFileUpload() {
   this.click_save = false
   this.load_file = this.$refs.file.files[0];
  },
  async submitFile() {
   let formData = new FormData();
   formData.append("file", this.load_file);
   await this.uploadFile({
    data: formData,
    use_case: "icon",
   }).then(() => {
    if (this.pageId.data.image && this.pageId.data.image.length > 1) {
     this.deleted_files.push(this.pageId.data.image)
    }
    this.pageId.data.image = this.file.url
    this.new_files.push(this.file.url)
   });
  },
  saveData() {
   if (this.$refs.form.validate()) {
    this.click_save = true
    let result = []
    for (let i in this.interfaceData.data) {
     result.push({
      interface_entity_id: this.interfaceData.data[i].id,
      value: this.interfaceData.data[i].value
     })
    }
    let result1 = [
     {
      text_entity_id: this.cardId,
      value: JSON.stringify(this.cards)
     },
    ]
    
    // for (let i in this.textData.data) {
    //  result1.push({
    //   text_entity_id: this.textData.data[i].id,
    //   value: this.textData.data[i].value
    //  })
    // }
    this.onInterfaceDataSave({
     language_id: 1,
     translations: result
    })
    this.onTextDataSave({
     language_id: 1,
     translations: result1
    })
    this.pageId.data.language_id = 1
    this.onPageSave({id: 37, data: this.pageId.data})
    this.onClose()
   }
  },
  changePosition(pos, id) {
   let elem = this.cards.splice(id, 1)
   switch (pos) {
    case 'up':
     this.cards.splice(id - 1, 0, elem[0])
     break
    case 'down':
     this.cards.splice(id + 1, 0, elem[0])
     break
    case 'delete':
     this.cards.splice(id, 1)
     break
   }
   this.key++
  },
  addData() {
   this.cards.push({
    title: '',
    article: ''
   })
  },
 }
}
</script>

<style scoped lang="scss">

</style>