<template>
  <v-container v-if="interfaceData.data">
<!--    <v-btn @click="test">///</v-btn>-->
    <v-form ref="form" lazy-validation>
      <PagesMainLogo class="mx-auto mb-5"/>
      <DevInput
        v-if="interfaceData.data[1]"
        v-model="interfaceData.data[1].value"
        :text="t['heading']"
        :rules="[v => !!v || t['required_field']]"
      />
      <DevInput
        v-if="interfaceData.data[0]"
        v-model="interfaceData.data[0].value"
        :text="t['subheading']"
        :rules="[v => !!v || t['required_field']]"
      />
      <v-card v-if="pageId.data" class="my-5 pa-4 border text-start">
        SEO
        <div class="grid_seo">
          <div>
            <DevInput
              v-model="pageId.data['seo_title']"
              :text="t['title']"
              :rules="[v => !!v || t['required_field']]"
            />
            <DevInput
              v-model="pageId.data['keywords']"
              :text="t['keywords']"
              :rules="[v => !!v || t['required_field']]"
            />
          </div>
          <div>
            <div>
              <v-input hide-details class="font12 text-center">
                {{ t['image'] }}
              </v-input>
              <DevImageBlock :images="[pageId.data.image]" contain :on-update="loadImage"/>
            </div>
          </div>
          <DevInput
            v-model="pageId.data['description']"
            :text="t['description']"
            text-area
            :rules="[v => !!v || t['required_field']]"
            class="mb-n4"
          />
        </div>
      </v-card>
    </v-form>
    <input
      type="file"
      id="file"
      ref="file"
      :accept="'.JPG, .PNG, .SVG, .GIF, .WebP'"
      style="display: none"
      v-on:change="handleFileUpload()"
    />
    <div class="fixed_btn">
      <v-btn @click="saveData" color="primary" fab>
        <v-icon class="min">mdi-upload</v-icon>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import PagesMainLogo from "components/pages/main/PagesMainLogo";
import DevInput from "components/dev/DevInput";
import {mapActions, mapGetters} from "vuex";
import DevImageBlock from "components/dev/DevImageBlock";

export default {
  name: "main_seo",
  components: {DevImageBlock, DevInput, PagesMainLogo},
  computed: {
    t() {
      return Object.assign(
        this.$store.state.t[this.$route.params.language]['fields'],
        this.$store.state.t[this.$route.params.language]['form'],
        this.$store.state.t[this.$route.params.language]['buttons']
      )
    },
    ...mapGetters(["file"]),
    ...mapGetters('pages', ['pageList', 'pageId']),
    ...mapGetters('interfaceData', ['interfaceGroupList', 'interfaceData'])
  },
  data: () => ({
    deleted_files: [],
    new_files: [],
    click_save: false,
    load_file: '',
    key: 0,
  }),
  watch: {
    load_file() {
      this.submitFile();
    },
  },
  mounted() {
    this.onPage(16)
    this.onInterfaceData({id: 49})
  },
  beforeDestroy() {
    this.onClose()
  },
  methods: {
    ...mapActions(['uploadFile', 'destroyFile']),
    ...mapActions('pages', ['onPageList', 'onPage', 'onPageSave', 'onPageCreate']),
    ...mapActions('interfaceData', [
      'onInterfaceGroupCreate',
      'onInterfaceGroupList',
      'onInterfaceData',
      'onInterfaceDataDelete',
      'onInterfaceDataCreate',
      'onInterfaceDataSave',
      'onInterfaceDataSavePage',
      'onTextDataCreate',
      'onTextDataDelete',
    ]),
    onClose() {
      if (this.click_save) {
        for (let del in this.deleted_files) {
          this.destroyFile(this.deleted_files[del])
        }
      } else {
        for (let del in this.new_files) {
          this.destroyFile(this.new_files[del])
        }
      }
      this.deleted_files = []
      this.new_files = []
      this.click_save = false
    },
    test() {
      // this.onPageList()
     
      // this.onInterfaceGroupCreate({
      //   page_id: 37,
      //   title: "content"
      // })
     
        // this.onInterfaceGroupList(37)
     
      // this.onPageCreate({
      //   alt: "faq",
      //   description: "faq",
      //   image: "1",
      //   keywords: "faq",
      //   name: "faq",
      //   seo_title: "faq",
      //   title: "faq",
      //   url: "faq",
      // })
     
      /*this.onInterfaceDataDelete(502)*/

      /*this.onInterfaceDataSave({
        language_id: 1,
        translations: [
          {
            interface_entity_id: 475,
            value: 'test'
          }
        ]
      })*/
     
      // this.onInterfaceDataCreate( {
      //   interface_group_id: 61,
      //   language_id: 1,
      //   title: "title",
      //   value: "FAQ",
      // })
     
      // this.onTextDataCreate( {
      //   interface_group_id: 61,
      //   language_id: 1,
      //   title: "card",
      //   value: JSON.stringify([]),
      // })
     
     // this.onTextDataDelete(7)
    },
    loadImage() {
      this.$refs.file.click();
    },
    handleFileUpload() {
      this.click_save = false
      this.load_file = this.$refs.file.files[0];
    },
    async submitFile() {
      let formData = new FormData();
      formData.append("file", this.load_file);
      await this.uploadFile({
        data: formData,
        use_case: "icon",
      }).then(() => {
        if (this.pageId.data.image && this.pageId.data.image.length > 1) {
          this.deleted_files.push(this.pageId.data.image)
        }
        this.pageId.data.image = this.file.url
        this.new_files.push(this.file.url)
      });
    },
    async saveData() {
      if (this.$refs.form.validate()) {
        this.click_save = true
        this.pageId.data.language_id = 1
        await this.onInterfaceDataSave({
          language_id: 1,
          translations: [
            {
              interface_entity_id: 475,
              value: this.interfaceData.data[1].value
            },
            {
              interface_entity_id: 476,
              value: this.interfaceData.data[0].value
            }
          ]
        })
        await this.onPageSave({id: 16, data: this.pageId.data})
          .then(() => {
            this.onClose()
          })
      }
    },
  }
}
</script>

<style scoped>

</style>
