<template>
  <DefaultPageModal
      :text="data.id ? t['author'] : t['add_author']"
      :on-close="onClose"
  >
    <v-form ref="form" lazy-validation>
      <DevInput
          :text="t['author_name']"
          v-model="data.name"
          :rules="[v => !!v || t['required_field']]"
          :key="key"
      />
    </v-form>
    <v-btn @click="saveData" class="primary">
      {{ data.id ? t['save'] : t['create'] }}
    </v-btn>
  </DefaultPageModal>
</template>

<script>
import DefaultPageModal from "components/default/page/DefaultPageModal";
import {mapGetters} from "vuex";
import DevInput from "components/dev/DevInput";
import DefaultPageLanguage from "components/default/page/DefaultPageLanguage";
import author from "@/store/modules/author";

export default {
  name: "PagesBlogAuthor",
  components: {DefaultPageLanguage, DevInput, DefaultPageModal},
  props: {
    onClose: {},
    onSave: {},
    onItem: {},
  },
  data: () => ({
    data: {},
    key: 0
  }),
  computed: {
    t() {
      return Object.assign(
          this.$store.state.t[this.$route.params.language]['page_toolbar'],
          this.$store.state.t[this.$route.params.language]['fields'],
          this.$store.state.t[this.$route.params.language]['buttons'],
          this.$store.state.t[this.$route.params.language]['form']
      )
    },
    ...mapGetters(["language"]),
    ...mapGetters("author", ["author"])
  },
  watch: {
    author() {
      this.data = this.author.data
      this.key++
    },
    language() {
      this.getItem()
    }
  },
  created() {
    this.getItem()
  },
  methods: {
    saveData() {
      if (this.$refs.form.validate()) {
        this.data.language_id = this.language
        this.onSave(this.data)
      }
    },
    getItem() {
      if(this.onItem) {
        this.onItem()
      }
    }
  }
}
</script>
