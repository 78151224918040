<template>
  <v-card class="d-flex flex-wrap flex-md-nowrap gap-4 border pa-4 mb-7">
    <DevImageBlock
      :text="t['image']"
      :images="Array(item['image'])"
      contain
      :on-update="loadImage"
    />
    <div class="flex-grow-1" :class="item['right'] ? 'order-md-first' : ''">
      <v-input hide-details class="text--darken-1 font12">
        {{ t['article_text'] }}
      </v-input>
      <VueEditor
        v-model="item['text']"
        @input="checkLink"
      />
    </div>
    <input
      type="file"
      id="file"
      ref="file"
      :accept="'.JPG, .PNG, .SVG, .GIF, .WebP'"
      style="display: none"
      v-on:change="handleFileUpload()"
    />
  </v-card>
</template>

<script>
import {VueEditor} from "vue2-editor";
import DevImageBlock from "components/dev/DevImageBlock";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "PagesMainFeatureItem",
  components: {DevImageBlock, VueEditor},
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          image: null,
          text: null
        }
      }
    }
  },
  data: () => ({
    deleted_files: [],
    new_files: [],
    load_file: '',
    click_save: false,
    toolbar: [
      [{header: [false, 1, 2, 3, 4, 5, 6]}],
      ["bold", "italic"],
      [
        {align: ""},
        {align: "center"},
        {align: "right"},
        {align: "justify"}
      ],
      ["blockquote"],
      [{list: "ordered"}, {list: "bullet"}],
      [{indent: "-1"}, {indent: "+1"}],
      [{color: ['#277faf', '#212121']}],
      ["link"],
      ["clean"]
    ],
  }),
  computed: {
    ...mapGetters(["file"]),
    t() {
      return Object.assign(
        this.$store.state.t[this.$route.params.language]['fields'],
        this.$store.state.t[this.$route.params.language]['form'],
        this.$store.state.t[this.$route.params.language]['buttons']
      )
    },
  },
  watch: {
    load_file() {
      this.submitFile();
    },
  },
  methods: {
    ...mapActions(['uploadFile', 'destroyFile']),
    loadImage() {
      this.$refs.file.click()
    },
    checkLink() {
      let blank = this.item.text.indexOf('<a href="/')
      if (blank > 0) {
        let temp = this.item.text.split('<a href="/')
        this.item.text = temp[0] + '<a href="/' + temp[1].replace('target="_blank"', '')
      }
    },
    handleFileUpload() {
      this.click_save = false
      this.load_file = this.$refs.file.files[0];
    },
    async submitFile() {
      let formData = new FormData();
      formData.append("file", this.load_file);
      await this.uploadFile({
        data: formData,
        use_case: "cover",
      }).then(() => {
        this.item.image = this.file.url
        /*if (this.interfaceData.data[i].value && this.interfaceData.data[i].value.length > 1) {
         this.deleted_files.push(this.interfaceData.data[i].value)
         }
         this.interfaceData.data[i].value = this.file.url
         this.new_files.push(this.file.url)*/
      });
    },
  }
}
</script>