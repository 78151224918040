<template>
  <v-container>
    <v-form lazy-validation ref="form" v-if="pageId.data && interfaceData.data">
      <DevInput
        v-if="interfaceData.data[0]"
        :text="t['title']"
        v-model="interfaceData.data[0].value"
        :rules="[v => !!v || t['required_field']]"
      />
      <div
        v-for="(c, cnt) in contacts"
        :key="'contact_' + cnt + '_' + key"
        class="d-flex flex-column flex-md-row justify-end"
      >
        <v-card width="100%" class="d-flex flex-wrap flex-md-nowrap gap-4 border pa-4 mb-7">
          <DevInput
            :text="t['first_name']"
            v-model="c.name"
            :rules="[v => !!v || t['required_field']]"
            class="flex-grow-1"
          />
          <DevInput
            :text="t['phone']"
            prefix="+358"
            v-model="c.phone"
            class="flex-grow-1"
          />
          <DevInput
            :text="t['email']"
            v-model="c.email"
            :rules="[v => !!v || t['required_field']]"
            class="flex-grow-1"
          />
        </v-card>
        <div class="d-flex flex-row flex-md-column order-first order-md-2">
          <v-btn icon color="primary" @click="changePosition('up', cnt)" :disabled="cnt === 0">
            <v-icon>mdi-arrow-up-thick</v-icon>
          </v-btn>
          <v-btn icon color="primary" @click="changePosition('down', cnt)" :disabled="contacts.length === cnt + 1">
            <v-icon>mdi-arrow-down-thick</v-icon>
          </v-btn>
          <v-btn icon color="red" @click="id = cnt, modalAskDelete = true">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="flex-grow-1 text-center mb-10">
        <v-btn :key="contacts.length" @click="addData" color="primary" fab :disabled="disabledAddButton">
          <v-icon class="min">mdi-plus</v-icon>
        </v-btn>
      </div>
      <div class="d-flex flex-wrap justify-space-around align-start" :key="key">
        <v-card class="px-4 pt-4 ma-1 border" width="100%" max-width="420">
          <DevInput :text="t['title']" v-model="sales.name"/>
          <DevInput :text="t['phone']" v-model="sales.phone"/>
          <DevInput :text="t['email']" v-model="sales.email"/>
        </v-card>
        <v-card class="px-4 pt-4 ma-1 border" width="100%" max-width="420">
          <DevInput :text="t['address']" v-model="address.name"/>
          <DevInput :text="t['street']" v-model="address.street"/>
          <DevInput :text="t['zip']" v-model="address.zip"/>
          <DevInput :text="t['city']" v-model="address.city"/>
          <DevInput :text="t['url']" v-model="address.url"/>
        </v-card>
        <v-card class="px-4 pt-4 ma-1 border" width="100%" max-width="420">
          <DevInput :text="t['title']" v-model="support.name"/>
          <DevInput :text="t['phone']" v-model="support.phone"/>
          <DevInput :text="t['email']" v-model="support.email"/>
        </v-card>
      </div>
      <v-card class="my-5 pa-4 border text-start">
        SEO
        <div class="grid_seo">
          <div>
            <DevInput
              v-model="pageId.data['seo_title']"
              :text="t['title']"
              :rules="[v => !!v || t['required_field']]"
            />
            <DevInput
              v-model="pageId.data['keywords']"
              :text="t['keywords']"
              :rules="[v => !!v || t['required_field']]"
            />
          </div>
          <div>
            <div>
              <v-input hide-details class="font12 text-center">
                {{ t['image'] }}
              </v-input>
              <DevImageBlock :images="[pageId.data.image]" contain :on-update="loadImage"/>
            </div>
          </div>
          <DevInput
            v-model="pageId.data['description']"
            :text="t['description']"
            text-area
            :rules="[v => !!v || t['required_field']]"
            class="mb-n4"
          />
        </div>
      </v-card>
    </v-form>
    <input
      type="file"
      id="file"
      ref="file"
      :accept="'.JPG, .PNG, .SVG, .GIF, .WebP'"
      style="display: none"
      v-on:change="handleFileUpload()"
    />
    <div class="fixed_btn">
      <v-btn @click="saveData" color="primary" fab>
        <v-icon class="min">mdi-upload</v-icon>
      </v-btn>
    </div>
    <v-dialog v-model="modalAskDelete" width="600">
      <DefaultPageAskDelete
        text="contact"
        :on-close="closeModals"
        :on-delete="deleteItem"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {VueEditor} from "vue2-editor";
import DevInput from "components/dev/DevInput";
import DevImageBlock from "components/dev/DevImageBlock";
import DefaultPageAskDelete from "components/default/page/DefaultPageAskDelete";

export default {
  name: "benefits",
  components: {DefaultPageAskDelete, DevImageBlock, DevInput, VueEditor},
  computed: {
    ...mapGetters(["file"]),
    ...mapGetters('pages', ['pageList', 'pageId']),
    ...mapGetters('interfaceData', ['interfaceGroupList', 'interfaceData', 'textData']),
    t() {
      return Object.assign(
        this.$store.state.t[this.$route.params.language]['fields'],
        this.$store.state.t[this.$route.params.language]['form'],
        this.$store.state.t[this.$route.params.language]['buttons']
      )
    },
    disabledAddButton() {
      let l = this.contacts ? this.contacts.length : 0
      if (l > 0)
        return !(this.contacts[l - 1]['name'].length &&
          (this.contacts[l - 1]['phone'].length || this.contacts[l - 1]['email'].length))
      return false
    },
  },
  data: () => ({
    contacts: [],
    address: {},
    support: {},
    sales: {},
    dataId: -1,
    id: -1,
    modalAskDelete: false,
    key: 0,
    deleted_files: [],
    new_files: [],
    click_save: false,
    load_file: '',
    toolbar: [
      [{header: [false, 1, 2, 3, 4, 5, 6]}],
      ["bold", "italic"],
      [
        {align: ""},
        {align: "center"},
        {align: "right"},
        {align: "justify"}
      ],
      ["blockquote"],
      [{list: "ordered"}, {list: "bullet"}],
      [{indent: "-1"}, {indent: "+1"}],
      [{color: ['#277faf', '#212121']}],
      ["link"],
      ["clean"]
    ],
  }),
  watch: {
    load_file() {
      this.submitFile();
    },
    interfaceData() {
      if (this.interfaceData.data && this.interfaceData.data[4]) {
        if (this.interfaceData.data[4].value === '-') this.interfaceData.data[4].value = ''
        if (this.interfaceData.data[5].value === '-') this.interfaceData.data[5].value = ''
        if (this.interfaceData.data[6].value === '-') this.interfaceData.data[6].value = ''
      }
    }
  },
  created() {
    this.getData()
  },
  beforeDestroy() {
    this.onClose()
  },
  methods: {
    ...mapActions(['uploadFile', 'destroyFile']),
    ...mapActions('pages', ['onPageList', 'onPage', 'onPageSave', 'onPageCreate']),
    ...mapActions('interfaceData',
      ['onInterfaceGroupCreate', 'onInterfaceGroupList', 'onInterfaceData', 'onTextData', 'onTextDataSave',
        'onInterfaceDataDelete', 'onInterfaceDataCreate', 'onInterfaceDataSave', 'onInterfaceDataSavePage']),

    async getData() {
      await this.onPage(32)
      await this.onInterfaceData({id: 56})
      await this.onTextData({id: 56}).then(() => {
        let temp = this.textData.data.filter(i => i.name === 'data')
        if (temp[0]) {
          this.dataId = temp[0].id
          if (temp[0].value) {
            temp = JSON.parse(temp[0].value)
            this.contacts = temp.contacts
            this.address = temp.address
            this.support = temp.support
            this.sales = temp.sales
            this.key++
          }
        }
      })
    },
    onClose() {
      if (this.click_save) {
        for (let del in this.deleted_files) {
          this.destroyFile(this.deleted_files[del])
        }
      } else {
        for (let del in this.new_files) {
          this.destroyFile(this.new_files[del])
        }
      }
      this.deleted_files = []
      this.new_files = []
      this.click_save = false
    },
    loadImage() {
      this.$refs.file.click();
    },
    handleFileUpload() {
      this.click_save = false
      this.load_file = this.$refs.file.files[0];
    },
    async submitFile() {
      let formData = new FormData();
      formData.append("file", this.load_file);
      await this.uploadFile({
        data: formData,
        use_case: "icon",
      }).then(() => {
        if (this.pageId.data.image && this.pageId.data.image.length > 1) {
          this.deleted_files.push(this.pageId.data.image)
        }
        this.pageId.data.image = this.file.url
        this.new_files.push(this.file.url)
      });
    },
    saveData() {
      if (this.$refs.form.validate()) {
        this.click_save = true
        let result = []
        for (let i in this.interfaceData.data) {
          result.push({
            interface_entity_id: this.interfaceData.data[i].id,
            value: this.interfaceData.data[i].value || '-'
          })
        }
        this.onInterfaceDataSave({
          language_id: 1,
          translations: result
        })
        this.onTextDataSave({
          language_id: 1,
          translations: [
            {
              text_entity_id: this.dataId,
              value: JSON.stringify({
                contacts: this.contacts,
                address: this.address,
                support: this.support,
                sales: this.sales,
              }) //this.textData.data[0].value
            },
          ]
        })
        this.pageId.data.language_id = 1
        this.onPageSave({id: 32, data: this.pageId.data})
        this.onClose()
      }
    },
    addData() {
      this.contacts.push({
        name: '',
        phone: '',
        email: ''
      })
    },
    changePosition(pos, id) {
      let elem = this.contacts.splice(id, 1)
      switch (pos) {
        case 'up':
          this.contacts.splice(id - 1, 0, elem[0])
          break
        case 'down':
          this.contacts.splice(id + 1, 0, elem[0])
          break
        case 'delete':
          this.contacts.splice(id, 1)
          break
      }
      this.key++
    },
    closeModals() {
      this.modalAskDelete = false
    },
    deleteItem() {
      this.changePosition('delete', this.id)
      this.closeModals()
    },
  }
}
</script>

<style scoped>

</style>
