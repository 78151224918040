<template>
  <div>
    <DefaultPageToolbar
      v-if="$route.name === 'articles'"
      :total="list.meta.total"
      :last_page="list.meta.last_page"
      :on-create="openNewItem"
    >
      <DefaultPageTable
        :title="$store.state.t[this.$route.params.language]['menu']['articles']"
        :data="list.data"
        :headers="headers"
        :on-active="changeActive"
        :on-edit="editItem"
        :on-delete="askDeleteItem"
        :ask-delete-fields="['name']"
      />
      <v-dialog v-model="modalAskDelete" width="600">
        <DefaultPageAskDelete
          text="article"
          :name="name"
          :on-close="closeModals"
          :on-delete="deleteItem"
        />
      </v-dialog>
    </DefaultPageToolbar>
    <router-view v-else/>
  </div>
</template>

<script>
import DefaultPageToolbar from "components/default/page/DefaultPageToolbar";
import {mapActions} from "vuex";
import DefaultPageTable from "components/default/page/DefaultPageTable";
import DefaultPageAskDelete from "components/default/page/DefaultPageAskDelete";

export default {
  name: "articles",
  components: {DefaultPageAskDelete, DefaultPageTable, DefaultPageToolbar},
  data: () => ({
    list: {
      meta: {
        total: 0
      }
    },
    id: 0,
    name: '',
    modalNewItem: false,
    modalEditItem: false,
    modalAskDelete: false
  }),
  computed: {
    headers() {
      return [
        {
          value: ["name"],
          text: this.$store.state.t[this.$route.params.language]['fields']['title']
        },
        {
          value: ["category"],
          text: this.$store.state.t[this.$route.params.language]['fields']['category']
        },
        {
          value: ["author"],
          text: this.$store.state.t[this.$route.params.language]['fields']['author']
        }
      ]
    }
  },
  watch: {
    '$route.query'() {
      this.getList()
    }
  },
  mounted() {
    if (this.$route.name === 'articles') {
      this.getList()
    }
  },
  methods: {
    //...mapActions("articleCategory", ["onArticleCategoryList", "onArticleCategoryCreate", "onArticleCategory", "onArticleCategorySave", "onArticleCategorySaveField", "onArticleCategoryDelete"]),
    ...mapActions("article", ["onArticleList", "onArticleSaveField", "onArticleDelete"]),
    async getList() {
      this.list = {meta: {}}
      await this.onArticleList({}).then(res => {
        this.list = res.data;
        for (let i in this.list.data) {
          this.transformList(this.list.data[i]);
        }
      })
    },
    transformList(data) {
      data.category = data.article_category.title
      data.author = data.article_author.name
    },
    closeModals() {
      this.modalNewItem = false;
      this.modalEditItem = false;
      this.modalAskDelete = false;
    },
    openNewItem() {
      //this.modalNewItem = true;
      this.$router.push({name: 'article_id', params: {id: 0}})
    },
    askDeleteItem(id, name) {
      this.id = id;
      this.name = name;
      this.modalAskDelete = true;
    },
    changeActive(id, state) {
      this.onArticleSaveField({
        id: id, data: {
          field: 'is_active',
          value: state
        }
      })
    },
    async deleteItem() {
      await this.onArticleDelete(this.id).then(() => {
        this.getList()
        this.closeModals()
      })
    },
    editItem(id) {
      this.id = id;
      //this.modalEditItem = true
      this.$router.push({name: 'article_id', params: {id: id}})
    },
    saveItem(data) {
      // this.onArticleCategorySave({id: data.id, data: data})
    },
    async getItem() {
      console.log('id: ' + this.id)
      // await this.onArticleCategory(this.id).then(res => {
      //   console.log(res.data)
      // })
    },
    async createItem(data) {
      // await this.onArticleCategoryCreate(data).then(() => {
      //   this.closeModals()
      // })
    }
  }
}
</script>
