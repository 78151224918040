import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'
import login from "@/views/pages/authorization/login";
import welcome from "@/views/pages/other/welcome";
import confirm_email from "@/views/pages/authorization/confirm_email";
import confirm_restore from "@/views/pages/authorization/confirm_restore";
import user_data from "@/views/pages/authorization/user_data";
import messages from "@/views/pages/other/messages";
import languages from "@/views/pages/settings/languages";
import settings from "@/views/settings";
import order_id from "@/views/order_id";
import orders from "@/views/orders";
import languageqq from "views/language";
import construction from "views/construction";
import cleaning from "views/cleaning";
import contacts from "views/contacts";
import construction_main from "views/pages/construction/construction_main";
import construction_services from "views/pages/construction/construction_services";
import construction_partners from "views/pages/construction/construction_partners";
import construction_seo from "views/pages/construction/construction_seo";
import cleaning_main from "views/pages/cleaning/cleaning_main";
import cleaning_services from "views/pages/cleaning/cleaning_services";
import cleaning_seo from "views/pages/cleaning/cleaning_seo";
import main from "views/main_page";
import main_seo from "views/pages/main_page/main_seo";
import features from "views/pages/main_page/features";
import benefits from "views/benefits";
import agree from "views/agree";
import blog from "views/blog";
import categories from "views/pages/blog/categories";
import articles from "views/pages/blog/articles";
import article_id from "views/pages/blog/article_id";
import authors from "views/pages/blog/authors";
import category_id from "views/pages/blog/category_id";
import company from "views/company";
import registry from "views/registry";
import webinar from "views/webinar";
import calculator from "views/calculator";
import integrations from "views/integrations.vue";
import faq from "views/faq.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/select_language',
    name: 'select_language',
    component: languageqq,
  },
  {
    path: '/:language',
    component: home,
    children: [
      {
        path: '/',
        name: 'home',
        component: welcome
      },
      {
        path: 'main',
        name: 'main',
        component: main,
        children: [
          {
            path: 'main_seo',
            name: 'main_seo',
            component: main_seo
          },
          {
            path: 'features',
            name: 'features',
            component: features
          },
        ]
      },
      {
        path: 'webinar',
        name: 'webinar',
        component: webinar
      },
      {
        path: 'calculator',
        name: 'calculator',
        component: calculator
      },
      {
        path: 'benefits',
        name: 'benefits',
        component: benefits
      },
      {
        path: 'agree',
        name: 'agree',
        component: agree
      },
      {
        path: 'integrations',
        name: 'integrations',
        component: integrations
      },
      {
        path: 'faq',
        name: 'faq',
        component: faq
      },
      {
        path: 'company',
        name: 'company',
        component: company
      },
      {
        path: 'registry',
        name: 'registry',
        component: registry
      },
      {
        path: 'blog',
        name: 'blog',
        component: blog,
        children: [
          {
            path: "articles",
            name: "articles",
            component: articles,
            children: [
              {
                path: ':id',
                name: 'article_id',
                component: article_id
              }
            ]
          },
          {
            path: "article-categories",
            name: "categories",
            component: categories,
            children: [
              {
                path: ':id',
                name: 'category_id',
                component: category_id
              }
            ]
          },
          {
            path: "authors",
            name: "authors",
            component: authors
          },
        ]
      },
      {
        path: "messages",
        name: "messages",
        component: messages
      },
      {
        path: "construction",
        name: "construction",
        component: construction,
        children: [
          {
            path: "main",
            name: "construction_main",
            component: construction_main
          },
          {
            path: "services",
            name: "construction_services",
            component: construction_services
          },
          {
            path: "partners",
            name: "construction_partners",
            component: construction_partners
          },
          {
            path: "seo",
            name: "construction_seo",
            component: construction_seo
          },
        ]
      },
      {
        path: "cleaning",
        name: "cleaning",
        component: cleaning,
        children: [
          {
            path: "main",
            name: "cleaning_main",
            component: cleaning_main
          },
          {
            path: "services",
            name: "cleaning_services",
            component: cleaning_services
          },
          {
            path: "seo",
            name: "cleaning_seo",
            component: cleaning_seo
          },
        ]
      },
      {
        path: "contacts",
        name: "contacts",
        component: contacts
      },
      {
        path: 'login',
        name: 'login',
        component: login
      },
      {
        path: "confirm-email/:id",
        name: "confirmEmail",
        component: confirm_email
      },
      {
        path: "confirm-restore/:id",
        name: "confirmRestore",
        component: confirm_restore
      },
      {
        path: "user-data",
        name: "userData",
        component: user_data
      },
      {
        path: 'orders',
        name: 'orders',
        component: orders,
        children: [
          {
            path: ":id",
            name: 'order_id',
            component: order_id
          }
        ]
      },
      {
        path: "settings",
        name: "settings",
        component: settings,
        children: [
          {
            name: "languages",
            path: "languages",
            component: languages
          }
        ]
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
