<template>
  <DefaultPageModal
      :text="data.id ? t['category'] : t['add_category']"
      :on-close="closeModal"
  >
    <v-form ref="form" lazy-validation :key="key">
      <DevInput
          :text="t['title']"
          v-model="data.title"
          :rules="[v => !!v || t['required_field']]"
      />
      <DevInput
          :text="t['url']"
          v-model="data.url"
          :rules="[v => !!v || t['required_field']]"
      />
      <v-card class="pa-4 border">
        <v-input hide-details>
          SEO
        </v-input>
        <div class="d-flex align-center">
          <DevImageBlock :images="Array(data.seo_image)" :key="key1" contain/>
          <v-btn @click="loadImage()" class="second ml-5 mt-4">
            {{ data.image ? t['change'] : t['add'] }}
          </v-btn>
        </div>
        <div class="font12 mt-n3 mb-1 red--text text-start">
          {{ error && !data.seo_image ? t['required_field'] : '' }}
        </div>
        <DevInput
            :text="t['title']"
            v-model="data.seo_title"
            :rules="[v => !!v || t['required_field']]"
        />
        <DevInput
            :text="t['keywords']"
            v-model="data.keywords"
            :rules="[v => !!v || t['required_field']]"
        />
        <DevInput
            text-area
            :text="t['description']"
            v-model="data.description"
            :rules="[v => !!v || t['required_field']]"
            class="mb-n4"
        />
      </v-card>

      <input
          type="file"
          id="file"
          ref="file"
          accept=".JPG, .PNG, .SVG, .GIF, .WebP"
          style="display: none"
          v-on:change="handleFileUpload()"
      />
    </v-form>
    <v-btn @click="saveData" class="primary mb-n5 mt-5">
      {{ data.id ? t['save'] : t['create'] }}
    </v-btn>
  </DefaultPageModal>
</template>

<script>
import DefaultPageModal from "components/default/page/DefaultPageModal";
import {mapActions, mapGetters} from "vuex";
import DevImageBlock from "components/dev/DevImageBlock";
import DefaultPageLanguage from "components/default/page/DefaultPageLanguage";
import DevInput from "components/dev/DevInput";

export default {
  name: "PagesBlogArticleCategory",
  components: {DevInput, DefaultPageLanguage, DevImageBlock, DefaultPageModal},
  props: {
    onClose: {},
    onSave: {},
    onItem: {}
  },
  data: () => ({
    data: {},
    key: 0,
    key1: 10000,
    deleted_image: [],
    new_files: [],
    load_file: '',
    click_save: false,
    error: false
  }),
  computed: {
    t() {
      return Object.assign(
          this.$store.state.t[this.$route.params.language]['page_toolbar'],
          this.$store.state.t[this.$route.params.language]['fields'],
          this.$store.state.t[this.$route.params.language]['buttons'],
          this.$store.state.t[this.$route.params.language]['form']
      )
    },
    ...mapGetters(["language", "file", "apiDomain"]),
    ...mapGetters("articleCategory", ["articleCategory"]),
  },
  watch: {
    load_file() {
      if (this.load_file) {
        this.submitFile();
      }
    },
    articleCategory() {
      this.data = this.articleCategory.data
      this.key++
    },
    language() {
      this.clearHelpers();
      this.getItem()
    }
  },
  created() {
    this.getItem()
  },
  beforeDestroy() {
    this.clearHelpers()
  },
  methods: {
    ...mapActions(["uploadFile", "destroyFile"]),
    clearHelpers() {
      if (this.click_save) {
        console.log('жмакнули кнопку сохранения записи')
        for (let del in this.deleted_image) {
          this.destroyFile(this.deleted_image[del])
        }
      } else {
        console.log('не сохраняя закрыли окно')
        for (let del in this.new_files) {
          this.destroyFile(this.new_files[del])
        }
      }
      this.deleted_image = []
      this.new_files = []
      this.click_save = []
    },
    closeModal() {
      this.clearHelpers()
      this.onClose()
    },
    saveData() {
      if (this.$refs.form.validate()) {
        this.data.language_id = this.language
        this.data.image = this.data.seo_image
        this.data.icon = this.data.seo_image
        delete this.data.article_category_id
        this.onSave(this.data)
        this.click_save = true
      }
      else {
        this.error = true
      }
    },
    getItem() {
      if(this.onItem) {
        this.onItem()
      }
    },
    loadImage() {
      this.error = false
      this.$refs.file.click()
    },
    handleFileUpload() {
      this.load_file = this.$refs.file.files[0];
    },
    async submitFile() {
      let formData = new FormData();
      formData.append("file", this.load_file);
      console.log(this.load_file);
      await this.uploadFile({
        data: formData,
        use_case: "icon",
      }).then(() => {
        if (this.data.seo_image) {
          this.deleted_image.push(this.data.seo_image)
        }
        this.data.seo_image = this.file.url
        this.new_files.push(this.file.url)
        this.key1++
      });
    },
  }

}
</script>

<style scoped>

</style>
