<template>
  <div>
    <v-input v-if="text" hide-details class="font12">
      {{ text }}
    </v-input>
    <v-select
      v-if="select"
      v-model="selected"
      :items="items"
      :rules="rules"
      :item-text="itemText"
      :item-value="itemValue"
      :menu-props="menu_props"
      :hide-details="hideDetails"
      :return-object="returnObject"
      :disabled="disabled"
      @input="inputSelected"
      @change="changeSelected"
      @blur="blurSelect"
      append-icon="mdi-chevron-down"
      outlined
      dense
      :multiple="multiple"
      :chips="chips"
      :clearable="clearable"
      :hint="hint"
      :persistent-hint="!!hint"
      :label="label"
    />
    <v-autocomplete
      v-else-if="autocomplete"
      v-model="selected"
      :items="items"
      :rules="rules"
      :item-text="itemText"
      :item-value="itemValue"
      :menu-props="menu_props"
      :hide-details="hideDetails"
      :return-object="returnObject"
      :disabled="disabled"
      @input="inputSelected"
      @change="changeSelected"
      @blur="blurSelect"
      append-icon="mdi-chevron-down"
      :multiple="multiple"
      :chips="chips"
      outlined
      dense
      :clearable="clearable"
      :hint="hint"
      :persistent-hint="!!hint"
      :label="label"
    />
    <v-textarea
      v-else-if="textArea"
      v-model="selected"
      :disabled="disabled"
      :hide-details="hideDetails"
      :rules="rules"
      @input="inputSelected"
      @change="changeSelected"
      @blur="blurSelect"
      outlined
      auto-grow
      :hint="hint"
      :persistent-hint="!!hint"
      :label="label"
    />
    <v-datetime-picker
      v-else-if="dateTime"
      v-model="selected"
      dateFormat="dd.MM.yyyy"
      @input="inputSelected"
      @change="changeSelected"
      :hint="hint"
      :persistent-hint="!!hint"
      :textFieldProps="{
        outlined: true,
        dense: true,
        class: 'mb-n4',
        rules: rules,
        'validate-on-blur': true,
      }"
      :datePickerProps="{
        'no-title': true,
        scrollable: true,
      }"
      :timePickerProps="{
        'no-title': true,
        scrollable: true,
        'ampm-in-title': true,
      }"
      :okText="t['save']"
      :clearText="t['clear']"
    >
      <template slot="dateIcon">
        <v-icon>mdi-calendar-outline</v-icon>
      </template>
      <template slot="timeIcon">
        <v-icon>mdi-clock-outline</v-icon>
      </template>
    </v-datetime-picker>
    <v-text-field-simplemask
      v-else-if="phone"
      v-model="selected"
      ref="phone"
      v-bind:properties="{
                  prefix: '+358',
                  suffix: '',
                  color: 'primary',
                  outlined: true,
                  dense: true,
                  placeholder: '',
                  required: true,
                  'validate-on-blur': true,
                  'background-color' : 'white',
                  disabled: disabled,
                  rules: [v => !!v || t['required_field'], v => (v && v.length >= 12) || t['bad_phone']]
                }"
      v-bind:options="{
                  inputMask: '## ### ## ##',
                  outputMask: '#########',
                  empty: null,
                  applyAfter: false,
                  alphanumeric: false,
                  lowerCase: false,
                }"
      v-bind:focus="focus"
      v-on:focus="focus = false"
      validate-on-blur
    />
    <v-text-field
      v-else
      v-model="selected"
      :hide-details="hideDetails"
      :disabled="disabled"
      :rules="rules"
      @input="inputSelected"
      @change="changeSelected"
      @blur="blurSelect"
      outlined
      dense
      :type="type"
      :hint="hint"
      :persistent-hint="!!hint"
      :label="label"
      :prefix="prefix"
    >
      <template slot="append-outer">
        <slot name="append-outer"/>
      </template>
      <template slot="append">
        <slot name="append"/>
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: 'DevInput',
  props: {
    select: Boolean,
    autocomplete: Boolean,
    textArea: Boolean,
    dateTime: Boolean,
    phone: Boolean,
    text: {
      type: [String, Function],
      default: '',
    },
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
    value: {
      type: [Number, String, Object, Array, Date],
      default: null,
    },
    itemText: {
      type: [String, Function],
      default: 'name',
    },
    itemValue: {
      type: [String, Function],
      default: 'id',
    },
    hint: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => {
        return []
      },
    },
    type: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: null
    },
    hideDetails: Boolean,
    disabled: Boolean,
    returnObject: Boolean,
    multiple: Boolean,
    chips: Boolean,
    clearable: Boolean,
    onBlur: {},
    prefix: {
      type: String,
      default: null
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('change', val)
      },
    },
    t() {
      return Object.assign(
        this.$store.state.t[this.$route.params.language]['fields'],
        this.$store.state.t[this.$route.params.language]['form'],
        this.$store.state.t[this.$route.params.language]['buttons'],
        this.$store.state.t[this.$route.params.language]['page_toolbar'],
      )
    },
  },
  data: () => ({
    selected: null,
    focus: false,
    menu_props: {
      bottom: true,
      offsetY: true,
      tile: true,
      'open-on-hover': false,
    },
  }),
  created() {
    this.selected = this.value
  },
  methods: {
    inputSelected() {
      this.$emit('input', this.selected)
    },
    changeSelected() {
      this.$emit('change', this.selected)
    },
    blurSelect() {
      if (this.onBlur) this.onBlur()
    }
  },
}
</script>

<style scoped lang="scss">
:deep() .theme--light.v-input--is-disabled input,
:deep() .theme--light.v-input--is-disabled textarea {
  color: rgba(0, 0, 0, 0.87) !important;
}

:deep() .v-chip--select {
  margin: 2px !important;
  background-color: $primary !important;
  color: white !important;
}
</style>
