<template>
  <div>
    <v-hover v-slot="{ hover }">
      <v-img
        v-if="image"
        :src="apiDomain + '/' + image"
        :width="width"
        :height="height"
        :min-height="minHeight"
        :contain="contain"
        :class="circle ? 'rounded-circle' : ''"
        :max-width="maxWidth"
        :max-height="maxHeight"
      >
        <template slot="placeholder">
          <v-card
            tile
            height="100%"
            class="d-flex align-center justify-center"
            color="grey"
          >
            <v-icon :size="height < 50 ? 30 : 40" class="white--text">mdi-image-off</v-icon>
          </v-card>
        </template>
        <template>
          <v-fade-transition>
            <v-sheet
              v-if="hover"
              width="100%"
              height="100%"
              class="d-flex"
              color="transparent"
            >
              <v-btn
                v-if="onOpen"
                @click="onOpen(id)"
                tile
                :width="onDelete || onUpdate ? '50%' : '100%'"
                height="100%"
                class="black white--text"
              >
                <v-icon size="30">mdi-magnify-plus-outline</v-icon>
              </v-btn>
              <v-btn
                v-if="onDelete"
                @click="onDelete(id)"
                tile
                :width="onOpen ? '50%' : '100%'"
                height="100%"
                class="red white--text"
              >
                <v-icon size="30">mdi-close-circle-outline</v-icon>
              </v-btn>
              <v-btn
                v-if="onUpdate"
                @click="onUpdate(id)"
                tile
                :width="onOpen ? '50%' : '100%'"
                height="100%"
                class="black white--text"
              >
                <v-icon size="30">mdi-reload</v-icon>
              </v-btn>
            </v-sheet>
          </v-fade-transition>
        </template>
      </v-img>
      <v-card
        v-else
        tile
        flat
        :height="height"
        :width="width"
        :min-height="minHeight"
        :max-width="maxWidth"
        :max-height="maxHeight"
        class="d-flex align-center justify-center"
        :class="circle ? 'rounded-circle' : ''"
        color="grey"
      >
        <v-icon size="40" class="white--text min">mdi-image-off</v-icon>
        <v-btn
          v-if="hover && onUpdate"
          @click="onUpdate()"
          tile absolute
          width="100%"
          height="100%"
          class="black white--text"
        >
          <v-icon size="30">mdi-upload</v-icon>
        </v-btn>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "DevImageBtn",
  computed: {
    ...mapGetters(["file", "apiDomain"])
  },
  props: {
    onUpdate: {},
    onDelete: {},
    onOpen: {},
    image: {
      type: String,
      default: ""
    },
    id: {
      type: [String, Number],
      default: 0
    },
    height: {
      type: [String, Number],
      default: 90
    },
    minHeight: {
      type: [String, Number],
      default: null
    },
    width: {
      type: [String, Number],
      default: 90
    },
    maxWidth: {
      type: [String, Number],
      default: null
    },
    maxHeight: {
      type: [String, Number],
      default: null
    },
    contain: Boolean,
    circle: Boolean,
    idKey: {
      type: [Number, String],
      default: null
    },
  },
}
</script>

<style scoped lang="scss">
.v-btn {
  min-width: 50px !important;
  
  &.black {
    background-color: rgba(black, 0.4) !important;
    
    &:hover {
      background-color: rgba(black, 0.8) !important;
    }
  }
  
  &.red {
    background-color: rgba(red, 0.3) !important;
    
    &:hover {
      background-color: rgba(red, 0.8) !important;
    }
  }
}
</style>
