<template>
  <DefaultPageToolbar
    :total="Number(list.meta.total)"
    :last_page="list.meta.last_page"
    :create-text="$store.state.t[this.$route.params.language]['page_toolbar']['add_service']"
    :on-create="openNewItem"
  >
    <DefaultPageTable
      :title="$store.state.t[this.$route.params.language]['menu']['service']"
      :data="list.data"
      :headers="headers"
      :on-edit="editItem"
      :on-delete="askDeleteItem"
      :on-active="activeItem"
      :ask-delete-fields="['name']"
    />
    <v-dialog v-model="modalNewItem" width="800" style="z-index: 1000">
      <ServiceModal v-if="modalNewItem" :on-save="createItem" :on-close="closeModals"/>
    </v-dialog>
    <v-dialog v-model="modalEditItem" width="800" style="z-index: 1000">
      <ServiceModal v-if="modalEditItem" :on-item="loadItem" :on-save="saveItem" :on-close="closeModals"/>
    </v-dialog>
    <v-dialog v-model="modalAskDelete" width="600" style="z-index: 1000">
      <DefaultPageAskDelete
        text="service"
        :name="name"
        :on-close="closeModals"
        :on-delete="deleteItem"
      />
    </v-dialog>
  </DefaultPageToolbar>
</template>

<script>
import DefaultPageToolbar from "components/default/page/DefaultPageToolbar";
import DefaultPageTable from "components/default/page/DefaultPageTable";
import {mapActions, mapGetters} from "vuex";
import DefaultPageAskDelete from "components/default/page/DefaultPageAskDelete";
import ServiceModal from "components/ServiceModal";

export default {
  name: "construction_services",
  components: {ServiceModal, DefaultPageAskDelete, DefaultPageTable, DefaultPageToolbar},
  data: () => ({
    list: {
      meta: {}
    },
    item: {},
    id: 0,
    name: '',
    modalAskDelete: false,
    modalNewItem: false,
    modalEditItem: false,
  }),
  computed: {
    headers() {
      return [
        {
          value: ["image", "name"],
          text: this.$store.state.t[this.$route.params.language]['fields']['service_name'],
          text_position: "name"
        }
      ]
    },
    t() {
      return Object.assign(
        this.$store.state.t[this.$route.params.language]['page_toolbar'],
        this.$store.state.t[this.$route.params.language]['fields'],
        this.$store.state.t[this.$route.params.language]['buttons'],
        this.$store.state.t[this.$route.params.language]['form'],
      )
    },
    ...mapGetters(["apiDomain"]),
  },
  watch: {
    '$route.query'() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    ...mapActions("service", [
      "onServiceList", "onService", "onServiceSave", "onServiceDelete", "onServiceFieldPut", "onServiceUnit", "onServiceCreate", "onServiceUnitPost", "onServiceUnitDelete"
    ]),
    async getList() {
      this.list = {meta: {}}
      await this.onServiceList({category: 15}).then(res => {
        this.list = res.data;
        for (let i in this.list.data) {
          this.transformList(this.list.data[i]);
        }
      })
    },
    transformList(data) {
      data.image = [{
        src: this.apiDomain + '/' + JSON.parse(data.image)[0],
        type: 'image'
      }]
    },
    closeModals() {
      this.modalAskDelete = false;
      this.modalNewItem = false;
      this.modalEditItem = false
      this.is_units = false
    },
    openNewItem() {
      this.modalNewItem = true;
    },
    askDeleteItem(id, name) {
      this.id = id;
      this.name = name;
      this.modalAskDelete = true;
    },
    async checkUnits() {
      await this.onServiceUnit({service: this.id}).then(res => {
        this.units = res.data.amount > 0;
      })
    },
    async deleteUnit(id) {
      id
        ? await this.onServiceUnitDelete(id).then(() => {
          this.onDelete()
        })
        : await this.onDelete()
    },
    async deleteItem() {
      await this.onServiceUnit({service: this.id}).then(res => {
        let id = res.data.data[0] ? res.data.data[0].id : null
        this.deleteUnit(id)
      })
    },
    async onDelete() {
      await this.onServiceDelete(this.id).then(() => {
        this.getList();
        this.closeModals()
      })
    },
    editItem(id) {
      this.id = id
      this.modalEditItem = true
    },
    loadItem() {
      this.onService({id: this.id})
    },
    async saveItem(data) {
      await this.onServiceSave({id: this.id, data: data}).then(() => {
        this.getList();
        this.closeModals()
      })
    },
    async createItem(data) {
      await this.onServiceCreate(data).then(res => {
        this.getList();
        this.closeModals()
      })
    },
    async createUnit(id) {
      await this.onServiceUnitPost({
        image: "uploads/aswpZu35148h3Sta9xroiDSPTS1IkSUfAf6WcfZl.png",
        language_id: 1,
        location_id: 1,
        name: id,
        number: "1000",
        price: "1",
        service_id: id
      }).then(() => {
      
      })
    },
    async activeItem(id, state) {
      this.id = id
      await this.checkUnits().then(() => {
        if (!this.units) {
          console.log('создать юнит')
          this.createUnit(id)
        }
        this.onServiceFieldPut({
          id: id, data: {
            field: "is_active",
            value: state
          }
        })
      })
    },
  }
}
</script>
