<template>
  <v-container v-if="interfaceData.data">
    <v-form lazy-validation ref="form">
      <DevInput
        v-if="interfaceData.data[2]"
        :text="t['title']"
        v-model="interfaceData.data[2].value"
        :rules="[v => !!v || t['required_field']]"
      />
      <div v-for="(a, art) in article" :key="art" class="d-flex flex-column flex-md-row justify-end">
        <PagesMainFeatureItem :item="a" />
        <div class="d-flex flex-row flex-md-column order-first order-md-2">
          <v-btn icon color="primary" @click="a['right'] = !a['right']">
            <v-icon>mdi-arrow-left-right-bold</v-icon>
          </v-btn>
          <v-btn icon color="primary" @click="changePosition('up', art)" :disabled="art === 0">
            <v-icon>mdi-arrow-up-thick</v-icon>
          </v-btn>
          <v-btn icon color="primary" @click="changePosition('down', art)" :disabled="article.length === art + 1">
            <v-icon>mdi-arrow-down-thick</v-icon>
          </v-btn>
          <v-btn icon color="red" @click="id = art, modalAskDelete = true">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="flex-grow-1 text-center">
        <v-btn :key="article.length" @click="addData" color="primary" fab :disabled="disabledAddButton">
          <v-icon class="min">mdi-plus</v-icon>
        </v-btn>
      </div>
    </v-form>
    <div class="fixed_btn">
      <v-btn @click="saveData" color="primary" fab>
        <v-icon class="min">mdi-upload</v-icon>
      </v-btn>
    </div>
    <v-dialog v-model="modalAskDelete" width="600">
      <DefaultPageAskDelete
        text="article"
        :on-close="closeModals"
        :on-delete="deleteItem"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import DevInput from "components/dev/DevInput";
import {mapActions, mapGetters} from "vuex";
import PagesMainFeatureItem from "components/pages/main/PagesMainFeatureItem";
import DefaultPageAskDelete from "components/default/page/DefaultPageAskDelete";

export default {
  name: "features",
  components: {DefaultPageAskDelete, PagesMainFeatureItem, DevInput},
  computed: {
    ...mapGetters('pages', ['pageList', 'pageId']),
    ...mapGetters('interfaceData', ['interfaceGroupList', 'interfaceData', 'textData']),
    t() {
      return Object.assign(
        this.$store.state.t[this.$route.params.language]['fields'],
        this.$store.state.t[this.$route.params.language]['form'],
        this.$store.state.t[this.$route.params.language]['buttons']
      )
    },
    disabledAddButton() {
      let l = this.article ? this.article.length : 0
      if (l > 0)
        return !this.article[l - 1].text.length
      return false
    },
  },
  data: () => ({
    click_save: false,
    article: [],
    id: -1,
    modalAskDelete: false,
    dataId: -1,
  }),
  watch: {
    load_file() {
      this.submitFile();
    },
  },
  created() {
    this.getData()
  },
  beforeDestroy() {
    this.onClose()
  },
  methods: {
    ...mapActions(['uploadFile', 'destroyFile']),
    ...mapActions('pages', ['onPageList', 'onPage', 'onPageSave']),
    ...mapActions('interfaceData',
      ['onInterfaceGroupCreate', 'onInterfaceGroupList', 'onInterfaceData', 'onTextData', 'onInterfaceDataCreate',
        'onTextDataCreate', 'onTextDataSave', 'onInterfaceDataSave', 'onInterfaceDataSavePage']),
    async getData() {
      await this.onInterfaceData({id: 52})
      await this.onTextData({id: 52}).then(() => {
        let temp = this.textData.data.filter(i => i.name === 'article')
        if (temp.length) {
          this.dataId = temp[0].id
          if (temp[0].value)
            temp = JSON.parse(temp[0].value)
          else temp = []
        }
        this.article = temp
      })
    },
    onClose() {
      /*      if (this.click_save) {
       for (let del in this.deleted_files) {
       this.destroyFile(this.deleted_files[del])
       }
       } else {
       for (let del in this.new_files) {
       this.destroyFile(this.new_files[del])
       }
       }
       this.deleted_files = []
       this.new_files = []
       this.click_save = false*/
    },
    closeModals() {
      this.modalAskDelete = false
    },
    deleteItem() {
      this.changePosition('delete', this.id)
      this.closeModals()
    },
    changePosition(pos, id) {
      let elem = this.article.splice(id, 1)
      switch (pos) {
        case 'up':
          this.article.splice(id - 1, 0, elem[0])
          break
        case 'down':
          this.article.splice(id + 1, 0, elem[0])
          break
        case 'delete':
          this.article.splice(id, 1)
          break
      }
    },
    addData() {
      let l = this.article ? this.article.length : []
      let r = l > 0 ? !this.article[l - 1].right : true
      this.article.push({
        image: '',
        right: r,
        text: '',
      })
    },
    async saveData() {
      if (this.$refs.form.validate()) {
        this.click_save = true
        let result = []
        for (let i in this.interfaceData.data) {
          result.push({
            interface_entity_id: this.interfaceData.data[i].id,
            value: this.interfaceData.data[i].value
          })
        }
        await this.onTextDataSave({
          language_id: 1,
          translations: [
            {
              text_entity_id: this.dataId,
              value: JSON.stringify(this.article) //this.textData.data[0].value
            },
          ]
        })
        await this.onInterfaceDataSave({
          language_id: 1,
          translations: result
        })
          .then(() => {
            this.onClose()
          })
      }
    }
  }
}
</script>