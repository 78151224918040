<template>
  <v-container>
    <v-form lazy-validation ref="form" v-if="pageId.data">
      <VueEditor v-if="textData.data && textData.data[0]" v-model="qq"/>
      {{ qq }}
      <v-card class="my-5 pa-4 border text-start">
        SEO
        <div class="grid_seo">
          <div>
            <DevInput
              v-model="pageId.data['seo_title']"
              :text="t['title']"
              :rules="[v => !!v || t['required_field']]"
            />
            <DevInput
              v-model="pageId.data['keywords']"
              :text="t['keywords']"
              :rules="[v => !!v || t['required_field']]"
            />
          </div>
          <div>
            <div>
              <v-input hide-details class="font12 text-center">
                {{ t['image'] }}
              </v-input>
              <DevImageBlock :images="[pageId.data.image]" contain :on-update="loadImage"/>
            </div>
          </div>
          <DevInput
            v-model="pageId.data['description']"
            :text="t['description']"
            text-area
            :rules="[v => !!v || t['required_field']]"
            class="mb-n4"
          />
        </div>
      </v-card>
    </v-form>
    <input
      type="file"
      id="file"
      ref="file"
      :accept="'.JPG, .PNG, .SVG, .GIF, .WebP'"
      style="display: none"
      v-on:change="handleFileUpload()"
    />
    <div class="fixed_btn">
      <v-btn @click="saveData" color="primary" fab>
        <v-icon class="min">mdi-upload</v-icon>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {VueEditor} from "vue2-editor";
import DevInput from "components/dev/DevInput";
import DevImageBlock from "components/dev/DevImageBlock";

export default {
  name: "registry",
  components: {DevImageBlock, DevInput, VueEditor},
  computed: {
    t() {
      return Object.assign(
        this.$store.state.t[this.$route.params.language]['fields'],
        this.$store.state.t[this.$route.params.language]['form'],
        this.$store.state.t[this.$route.params.language]['buttons']
      )
    },
    ...mapGetters(["file"]),
    ...mapGetters('pages', ['pageList', 'pageId']),
    ...mapGetters('interfaceData', ['interfaceGroupList', 'interfaceData', 'textData'])
  },
  data: () => ({
    deleted_files: [],
    new_files: [],
    click_save: false,
    load_file: '',
    toolbar: [
      [{header: [false, 1, 2, 3, 4, 5, 6]}],
      ["bold", "italic"],
      [
        {align: ""},
        {align: "center"},
        {align: "right"},
        {align: "justify"}
      ],
      ["blockquote"],
      [{list: "ordered"}, {list: "bullet"}],
      [{indent: "-1"}, {indent: "+1"}],
      [{color: ['#277faf', '#212121']}],
      ["link"],
      ["clean"]
    ],
  }),
  watch: {
    load_file() {
      this.submitFile();
    },
  },
  mounted() {
    this.onPage(33)
    this.onTextData({id: 57})
  },
  beforeDestroy() {
    this.onClose()
  },
  methods: {
    ...mapActions(['uploadFile', 'destroyFile']),
    ...mapActions('pages', ['onPageList', 'onPage', 'onPageSave', 'onPageCreate']),
    ...mapActions('interfaceData', ['onInterfaceGroupCreate', 'onInterfaceGroupList', 'onInterfaceData', 'onTextData', 'onTextDataSave', 'onInterfaceDataDelete', 'onInterfaceDataCreate', 'onInterfaceDataSave', 'onInterfaceDataSavePage']),
    onClose() {
      if (this.click_save) {
        for (let del in this.deleted_files) {
          this.destroyFile(this.deleted_files[del])
        }
      } else {
        for (let del in this.new_files) {
          this.destroyFile(this.new_files[del])
        }
      }
      this.deleted_files = []
      this.new_files = []
      this.click_save = false
    },
    loadImage() {
      this.$refs.file.click();
    },
    handleFileUpload() {
      this.click_save = false
      this.load_file = this.$refs.file.files[0];
    },
    async submitFile() {
      let formData = new FormData();
      formData.append("file", this.load_file);
      await this.uploadFile({
        data: formData,
        use_case: "icon",
      }).then(() => {
        if (this.pageId.data.image && this.pageId.data.image.length > 1) {
          this.deleted_files.push(this.pageId.data.image)
        }
        this.pageId.data.image = this.file.url
        this.new_files.push(this.file.url)
      });
    },
    saveData() {
      if (this.$refs.form.validate()) {
        this.click_save = true
        let result = []
        for (let i in this.interfaceData.data) {
          result.push({
            interface_entity_id: this.interfaceData.data[i].id,
            value: this.interfaceData.data[i].value
          })
        }
        let result1 = []
        for (let i in this.textData.data) {
          result1.push({
            text_entity_id: this.textData.data[i].id,
            value: this.textData.data[i].value
          })
        }
        this.onInterfaceDataSave({
          language_id: 1,
          translations: result
        })
        this.onTextDataSave({
          language_id: 1,
          translations: result1
        })
        this.pageId.data.language_id = 1
        this.onPageSave({id: 33, data: this.pageId.data})
        this.onClose()
      }
    },
  }
}
</script>

<style scoped>

</style>
