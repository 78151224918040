<template>
  <div>
    {{ $route.params }}
  </div>
</template>

<script>
export default {
  name: "category_id"
}
</script>

<style scoped>

</style>
