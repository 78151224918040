<template>
  <v-footer app inset color="grey lighten-3" :height="56" class="justify-center justify-sm-space-between">
    <div class="d-inline-block">
      © 2020-{{ new Date().getFullYear() }} {{t.rights}}
    </div>
    <v-btn text plain class="text-none" href="https://prosofta.fi/" target="_blank">
      {{t.developer}}
      <span class="primary--text font-weight-bold ml-2">
        ProSofta
      </span>
    </v-btn>
  </v-footer>
</template>

<script>
export default {
  name: "DefaultFooter",
  computed: {
    t() {
      return Object.assign(this.$store.state.t[this.$route.params.language]['footer'])
    }
  }
}
</script>

<style scoped lang="scss">
.v-footer, .v-btn {
  font-size: 12px !important;
  color: var(--v-grey-darken1) !important;
  font-weight: 400 !important;
  letter-spacing: .8px !important;
}
</style>
