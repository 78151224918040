<template>
 <div style="width: 100%">
  <v-card class="d-flex flex-wrap flex-md-nowrap flex-column gap-4 border px-4 pt-4" width="100%" >
    <DevInput
     v-model="item['title']"
     :text="t['heading']"
     hide-details
    />
   <div>
    <v-input hide-details class="font12">
     {{ t['article_id'] }}
    </v-input>
    <VueEditor
     v-model="item['article']"
    />
   </div>
  </v-card>
 </div>
</template>

<script>
import DevInput from "components/dev/DevInput.vue";
import {VueEditor} from "vue2-editor";

export default {
 name: "PagesFaqArticlesItem",
 components: {VueEditor, DevInput},
 props: {
  item: {
   type: Object,
   default: () => {
    return {
     title: null,
     article: null
    }
   }
  }
 },
 data: () => ({
  deleted_files: [],
  new_files: [],
  load_file: '',
  click_save: false,
 }),
 computed: {
  t() {
   return Object.assign(
    this.$store.state.t[this.$route.params.language]['fields'],
    this.$store.state.t[this.$route.params.language]['form'],
    this.$store.state.t[this.$route.params.language]['buttons'],
    this.$store.state.t[this.$route.params.language]['menu'],
   )
  },
 },
}
</script>

<style scoped lang="scss">

</style>